
export const SvgUser = (props) => {
  return (
    <svg

      viewBox="0 0 25 25"
      fill="currentcolor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.5 14.063a7.033 7.033 0 007.031-7.032A7.033 7.033 0 0012.5 0a7.033 7.033 0 00-7.031 7.031 7.033 7.033 0 007.031 7.032zm6.25 1.562h-2.69a8.51 8.51 0 01-7.12 0H6.25A6.25 6.25 0 000 21.875v.781A2.344 2.344 0 002.344 25h20.312A2.344 2.344 0 0025 22.656v-.781a6.25 6.25 0 00-6.25-6.25z"

      />
    </svg>
  )
}

export const SvgCalendar = (props) => {
  return (
    <svg
      viewBox="0 0 24 28"
      fill="currentcolor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M.014 24.907c0 1.42 1.135 2.574 2.535 2.574h18.596c1.4 0 2.535-1.153 2.535-2.574V10.322H.014v14.585zm16.904-10.51a.64.64 0 01.634-.643h2.114a.64.64 0 01.633.643v2.145a.64.64 0 01-.633.644h-2.114a.64.64 0 01-.634-.644v-2.145zm0 6.864a.64.64 0 01.634-.644h2.114a.64.64 0 01.633.644v2.145a.64.64 0 01-.633.643h-2.114a.64.64 0 01-.634-.643V21.26zm-6.761-6.864a.64.64 0 01.633-.643h2.114a.64.64 0 01.633.643v2.145a.64.64 0 01-.633.644H10.79a.64.64 0 01-.633-.644v-2.145zm0 6.864a.64.64 0 01.633-.644h2.114a.64.64 0 01.633.644v2.145a.64.64 0 01-.633.643H10.79a.64.64 0 01-.633-.643V21.26zm-6.762-6.864a.64.64 0 01.634-.643h2.113a.64.64 0 01.634.643v2.145a.64.64 0 01-.634.644H4.029a.64.64 0 01-.634-.644v-2.145zm0 6.864a.64.64 0 01.634-.644h2.113a.64.64 0 01.634.644v2.145a.64.64 0 01-.634.643H4.029a.64.64 0 01-.634-.643V21.26zm17.75-17.802h-2.536V.885a.854.854 0 00-.845-.858h-1.69a.854.854 0 00-.846.858V3.46H8.466V.885a.854.854 0 00-.845-.858H5.93a.854.854 0 00-.846.858V3.46H2.55c-1.4 0-2.535 1.153-2.535 2.574v2.574H23.68V6.033c0-1.421-1.136-2.574-2.535-2.574z"
      />
    </svg>
  )
}

export const SvgUpload = (props) => {
  return (
    <svg
      viewBox="0 0 33 23"
      fill="currentcolor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.394 22.774c-4.046 0-7.328-3.271-7.328-7.303 0-3.185 2.046-5.893 4.895-6.892-.005-.137-.01-.274-.01-.411 0-4.483 3.643-8.114 8.142-8.114A8.14 8.14 0 0120.15 4.12a4.871 4.871 0 012.712-.822c2.697 0 4.885 2.181 4.885 4.869 0 .619-.117 1.207-.326 1.755 2.972.598 5.21 3.22 5.21 6.36 0 3.585-2.915 6.49-6.512 6.49H7.394zm4.02-11.005a1.21 1.21 0 000 1.719 1.224 1.224 0 001.724 0l1.985-1.978v6.8c0 .675.544 1.218 1.221 1.218s1.221-.543 1.221-1.217v-6.8l1.985 1.977a1.22 1.22 0 001.725 0 1.214 1.214 0 000-1.72l-4.071-4.056a1.219 1.219 0 00-1.725 0l-4.07 4.057h.004z"

      />
    </svg>
  )
}

export const SvgDelete = (props) => {
  return (
    <svg
      viewBox="0 0 30 34"
      fill="currentcolor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.993 30.486a3.062 3.062 0 003.062 3.062h18.372a3.062 3.062 0 003.061-3.062V9.052H2.993v21.434zm17.35-16.33a1.02 1.02 0 112.042 0v14.289a1.02 1.02 0 11-2.041 0v-14.29zm-6.123 0a1.02 1.02 0 012.041 0v14.289a1.02 1.02 0 01-2.041 0v-14.29zm-6.124 0a1.02 1.02 0 112.041 0v14.289a1.02 1.02 0 01-2.041 0v-14.29zM28.509 2.927h-7.655l-.6-1.193a1.53 1.53 0 00-1.371-.848h-7.291a1.513 1.513 0 00-1.366.848l-.6 1.193H1.973a1.02 1.02 0 00-1.02 1.02V5.99a1.02 1.02 0 001.02 1.02h26.537a1.021 1.021 0 001.02-1.02V3.95a1.02 1.02 0 00-1.02-1.02z"
      />
    </svg>
  )
}

export const SvgSearch = (props) => {
  return (
    <svg
      viewBox="0 0 25 25"
      fill="currentcolor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
        <path
          d="M20.313 10.156c0 2.242-.728 4.312-1.954 5.992l6.182 6.186a1.565 1.565 0 01-2.212 2.212l-6.181-6.187a10.099 10.099 0 01-5.992 1.953C4.546 20.313 0 15.768 0 10.156 0 4.546 4.546 0 10.156 0s10.156 4.546 10.156 10.156zm-10.157 7.031a7.032 7.032 0 100-14.064 7.032 7.032 0 000 14.064z"
        />
     
    </svg>
  )
}

export const SvgKey = (props) => {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="currentcolor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
        <path
          d="M13.125 13.75A6.875 6.875 0 106.25 6.875c0 .73.113 1.438.324 2.098l-6.3 6.3a.937.937 0 00-.274.665v3.124c0 .52.418.938.938.938h3.125c.519 0 .937-.418.937-.938V17.5h1.563c.519 0 .937-.418.937-.938V15h1.563c.25 0 .488-.098.664-.273l1.3-1.301c.66.21 1.368.324 2.098.324zm1.563-10a1.563 1.563 0 110 3.125 1.563 1.563 0 010-3.125z"
        />
     
    </svg>
  )
}

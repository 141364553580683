import { API_URL } from "./api";

export const getTotalUsuarios = async () => {
    const response = await fetch(`${API_URL}/numusuarios`);
    return response.json();
  };
  
  export const getTotalPremios = async () => {
    const response = await fetch(`${API_URL}/numpremios`);
    return response.json();
  };
  
  export const getTotalPremiosSolicitados = async () => {
    const response = await fetch(`${API_URL}/numsolicitados`);
    return response.json();
  };
  
  export const getResgates = async () => {
    const response = await fetch(`${API_URL}/resgates/historico`);
    return response.json();
  };
  
  export const getMoedasAtribuidas = async () => {
    const response = await fetch(`${API_URL}/moedas/historico`);
    return response.json();
  };

  export const getMoedasAtribuidasId = async (id) => {
    const response = await fetch(`${API_URL}/moedas/historico?id=${id}`);
    return response.json();
  };

  export const getDistribuicoesMoedas = async () => {
    const response = await fetch(`${API_URL}/distribuicoes`);
    return response.json();
  };

  export const getDistribuicoesMoedasId = async (id) => {
    const response = await fetch(`${API_URL}/distribuicoes?id=${id}`);
    return response.json();
  };


  
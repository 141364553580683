import React, { useState, useEffect } from "react";
import GlobalStyle, { Blue, PrimaryColor } from "../styles/global";
import styled from "styled-components";
import { IconInput } from "../styles/global";
import logo from "../assets/icons/logo-banco-da-felicidade.svg";
import { recoverPassword, updatePassword } from "../controllers/userController";
import { FormContainer, LoginContainer } from "../styles/styles";
import { Link } from "react-router-dom";
import Inputmask from "inputmask";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import bgYellow from "../assets/icons/yellow-footer.svg";


const LoginSection = styled.section`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: ${Blue};
    position: relative;

    .curve {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 30%;
    background-image: url(${bgYellow}); 
    background-size: cover;
    background-position: center;
  }

  .curve-red {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 30%;
    background: ${PrimaryColor};
    clip-path: polygon(60% 79%, 71% 83%, 85% 88%, 100% 91%, 100% 100%, 0 100%, 0 71%, 18% 70%, 33% 72%, 46% 75%);  
  }
  `;

function RecoverPassword() {
  const [cpf, setCpf] = useState("");
  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");
  const [confirmarSenha, setConfirmarSenha] = useState("");
  const [step, setStep] = useState(1);

  useEffect(() => {
    Inputmask("999.999.999-99").mask(document.getElementById("cpf"));
  }, []);

  const removeMaskCPF = (cpf) => {
    return cpf.replace(/\D/g, ""); // Remove tudo que não é número
  };


  const handleSubmitFirstStep = async (e) => {
    e.preventDefault();

    try {
      await recoverPassword(removeMaskCPF(cpf), email);
      toast.success("Usuário encontrado!"); // Toast de sucesso
      setStep(2);
    } catch (error) {
      toast.error("CPF ou email inválido. Por favor, tente novamente."); // Toast de erro
    }
  };

  const handleSubmitSecondStep = async (e) => {
    e.preventDefault();

    if (senha !== confirmarSenha) {
      toast.error("As senhas não coincidem!"); // Toast de erro
      return;
    }

    try {
      await updatePassword(removeMaskCPF(cpf), senha);
      toast.success("Senha alterada com sucesso! Redirecionado pro login!"); // Toast de sucesso
      setTimeout(() => {
        window.location.href = "/login"; // Redireciona para login após sucesso
      }, 5000);
    } catch (error) {
      toast.error("Erro ao alterar a senha. Tente novamente."); // Toast de erro
    }
  };

  return (
    <>
      <GlobalStyle />
      <ToastContainer /> 
      <LoginSection>
        <LoginContainer>
          <Link to="/">
            <img src={logo} alt="Logo Banco da Felicidade" />
          </Link>

          {step === 1 ? (
            <>
              <div>
                <h1>Que Pena :c</h1>
                <p>
                  Mas fique tranquilo. Basta informar o seu e-mail e o CPF para
                  você recuperar a senha.
                </p>
              </div>
              <form onSubmit={handleSubmitFirstStep}>
                <label htmlFor="cpf" style={{ color: "black" }}>
                  Usuário
                </label>
                <IconInput>
                  <input
                    type="text"
                    id="cpf"
                    name="cpf"
                    placeholder="Digite seu CPF"
                    autoComplete="off"
                    value={cpf}
                    onChange={(e) => setCpf(e.target.value)}
                    required
                  />
                </IconInput>
                <label htmlFor="cpf" style={{ color: "black" }}>
                  Email
                </label>
                <IconInput>
                  <input
                    type="email"
                    id="email"
                    autoComplete="off"
                    name="email"
                    placeholder="Digite seu e-mail"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </IconInput>
                <FormContainer direction="column">
                  <button type="submit">Continuar</button>
                </FormContainer>
              </form>
            </>
          ) : (
            <>
              <h1>Cadastre uma nova senha!</h1>
              <p> Lembre-se de cadastrar uma senha diferente da anterior.</p>
              <form onSubmit={handleSubmitSecondStep}>
                <label htmlFor="password" style={{ color: "black" }}>
                  Senha
                </label>
                <IconInput>
                  <input
                    type="password"
                    id="senha"
                    name="senha"
                    autoComplete="off"
                    placeholder="Digite a Nova Senha"
                    value={senha}
                    onChange={(e) => setSenha(e.target.value)}
                    required
                  />
                </IconInput>
                <label htmlFor="password" style={{ color: "black" }}>
                  Nova Senha
                </label>
                <IconInput>
                  <input
                    type="password"
                    id="confirmarSenha"
                    autoComplete="off"
                    name="confirmarSenha"
                    placeholder="Confirmar a Nova Senha"
                    value={confirmarSenha}
                    onChange={(e) => setConfirmarSenha(e.target.value)}
                    required
                  />
                </IconInput>
                <FormContainer direction="column">
                  <button type="submit">Alterar Senha</button>
                </FormContainer>
              </form>
            </>
          )}
          <a href="/">Voltar</a>
        </LoginContainer>
        <div class="curve"/>
        <div class="curve-red"/>
      </LoginSection>
    </>
  );
}

export default RecoverPassword;

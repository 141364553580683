import styled from "styled-components"
import {  SecondaryColor, Blue, Green } from "../../styles/global";

export const Header = styled.header`
  background: linear-gradient(165deg, ${SecondaryColor} 20%, ${Blue} 0%);
  color: white;
  padding: 14px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  position: relative;

  .headerButtons{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .logo {
      width: 50px;
    }

    > button {
      background-color: transparent;

      > svg {
        height: 20px;
      }
    }
  }

  .info {
    display: flex;
    align-items: center;
    gap: 12px;
    width: 100%;

    > svg {
      width:  100px;
      height: 50px;
    }

    .userInfo {
    text-align: left;

      .headerText {
        font-size: 12px;
        font-weight: 300;
      }

      .userName {
        font-size: 16px;
      }

      .userDetails {
        font-size: 15px;
      }
    }
  }

  .saldo {
    width: 80%;
    background-color: #FFC107;
    color: white;
    padding: 10px;
    border-radius: 10px;
    text-align: center;

    .saldoTitle {
      font-size: 14px;
      font-weight: 300;
    }

    .saldoValue {
      font-size: 20px;
      font-weight: 400;
    }
  }
`;

export const HomeContent = styled.div`
  background-color: #fff;
  position: relative;
  padding-block: 40px;

  .emocionarSection {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .emocionarButton {
    position: absolute;
    margin: 0 auto;
    top: -25px;
    background-color: ${Green};
    padding: 10px 60px;
    border-radius: 30px; 
    border: none;
    cursor: pointer;
    font-size: 14px;
  }
`;

export const HistoricoSection = styled.div`
  width: 100%;
  padding: 0px;
  border-radius: 0px;
  margin: 0px;

    .imgIcon {
      width: 35px;
      height: 35px;
    }

    
    .historicoItem {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;
      padding-bottom: 20px;
      border-bottom: 1px solid #ccc;
      gap:16px;
        
      .itemType {
        display: flex;
        align-items: center;
        gap: 16px
      }


      .historicoName {
        color: #000;
        font-size: 16px;
        font-weight: 400;
      }

      .historicoDate {
        display: flex;
        gap: 6px;

        > p {
          font-size: 12px;
          color: #777;
        }
      }

      .historicoAmount {
        font-size: 18px;
        color: #333;
      }
    }
    
    
`;

export const styles = {
  Title: {
    fontSize: "14px",
    marginBottom: "15px",
    color: "#000",
    fontWeight: 400
  },
  moedasSection: {
    backgroundColor: '#F3F3F3',
    padding: "20px",
    borderRadius: "10px",
    marginBlock: "25px",
    textAlign: "center",
  },
  sectionTitle: {
    color: '#000',
    fontSize: "14px",
    fontWeight: "400",
    marginBottom: "10px",
  },
  moedasContainer: {
    display: "flex",
    alignItems: "center",
  },
  moedaItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  moedaIcon: {
    width: "60px",
    height: "60px",
  },
  moedaText: {
    fontSize: "16px",
    color: '#7E8392'
  },
};
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as styles from "./styles"; 
import GlobalStyle from "../../styles/global";
import Header from "../../components/header";
import { Container, Content, ContentSection } from "../../styles/styles";
import {
  PrimaryColor,
  SecondaryColor,
  TextColor,
  TextColorLight,
  BackgroundColor,
  Accent,
  Border,
  ErrorComponent,
} from "../../styles/global";
import { BackBar, BackButton } from "../../styles/global";
import styled from "styled-components";
import { SvgUpload } from "../../assets/icons";
import { createPremio } from "../../controllers/premioController";
import { IoCloudUploadOutline } from "react-icons/io5";
import { ToastContainer, toast } from "react-toastify";

const SubmitButton = styled.button`
  padding: 10px 70px;
  border-radius: 30px; 
  border: none;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  cursor: pointer;

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const FileInput = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 20px;
  padding: 4px;
  border-radius: 8px;
  border: 1px solid ${Border};
  font-size: 14px;
  cursor: pointer;

  input {
    display: none;
  }

  svg {
    height: 30px;
    color: #000;
  }

  img {
    width: 60%;
    border-radius: 8px;
  }

  .interna {
    background-color: ${BackgroundColor};
    padding-inline: 8px;
    padding-block: 16px;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .interna span {
    color: #000;
    width: 80%;
  }
`;

const CriarPremio = () => {
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [value, setValue] = useState("");
  const [estoque, setEstoque] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [file, setFile] = useState(null);

  const handleTitleChange = (e) => setTitle(e.target.value);
  const handleValueChange = (e) => setValue(e.target.value);
  const handleEstoqueChange = (e) => setEstoque(e.target.value);
  const handleDescriptionChange = (e) => setDescription(e.target.value);
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFile(file);
    setPreviewImage(URL.createObjectURL(file)); // Mostra a imagem selecionada
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("nome", title);
    formData.append("valor", value);
    formData.append("estoque", estoque);
    formData.append("descricao", description);
    if (image) formData.append('url_imagem', image);
    
    try {
      await createPremio(formData);
      navigate("/premios");
    } catch (error) {
      setErrorMessage("Erro ao criar o prêmio.");
    }
  };

  return (
    <>
    <ToastContainer />
      <GlobalStyle />
      <Container
        style={{
          background: `linear-gradient(10deg, #FF2D55 20%, #30B0C7  0%)`,
        }}
        fullWidth
        direction="column"
        align="center"
        padding="0px"
        height="100%"
      >
        <Container direction="column" gap="40px" fullWidth>
          <BackBar>
            <BackButton onClick={() => navigate(-1)}>← Voltar</BackButton>
            <BackButton onClick={() => navigate("/")}>X</BackButton>
          </BackBar>

          <ContentSection fullWidth padding="0px">
            <Content light padding="0px" sx={{paddingTop: '40px'}}>
              <styles.PremioForm onSubmit={handleSubmit}>
                <p>Novo Prêmio</p>
                <Container fullWidth direction="column" gap="18px" padding="20px">
                  <styles.InputField
                    type="text"
                    placeholder="Nome do prêmio"
                    value={title}
                    onChange={handleTitleChange}
                    required
                  />
                  <styles.RequiredText>Obrigatório *</styles.RequiredText>

                  <styles.InputField
                    type="number"
                    placeholder="Valor do prêmio"
                    value={value}
                    onChange={handleValueChange}
                    required
                  />
                  <styles.RequiredText>Obrigatório *</styles.RequiredText>

                  <styles.InputField
                    type="number"
                    placeholder="Estoque disponível"
                    value={estoque}
                    onChange={handleEstoqueChange}
                    required
                  />
                  <styles.RequiredText>Obrigatório *</styles.RequiredText>

                  <styles.TextArea
                    placeholder="Descrição do prêmio"
                    value={description}
                    onChange={handleDescriptionChange}
                    required
                  />
                  <styles.RequiredText>Obrigatório *</styles.RequiredText>

                  <FileInput>
                    <div className="interna">
                      <span>Clique aqui para fazer o upload</span>
                      <IoCloudUploadOutline />
                      <input
                        type="file"
                        onChange={handleFileChange}
                        accept="image/*"
                      />
                    </div>
                    {previewImage && (
                      <img
                        src={previewImage}
                        alt="Pré-visualização da imagem"
                      />
                    )}
                  </FileInput>
                  <styles.RequiredText>Obrigatório *</styles.RequiredText>

                  {/* {errorMessage && <styles.ErrorMessage>{errorMessage}</styles.ErrorMessage>} */}
                  {errorMessage && (
                    <ErrorComponent message={errorMessage} duration={8000} />
                  )}

                  <SubmitButton
                    type="submit"
                    style={{ backgroundColor: "#FFCC00",  alignSelf: 'center' }}
                  >
                    Confirmar
                  </SubmitButton>
                </Container>
              </styles.PremioForm>
            </Content>
          </ContentSection>
        </Container>
      </Container>
    </>
  );
};

export default CriarPremio;

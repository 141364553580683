import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Header from '../../components/header';
import GlobalStyle, { IconInput } from '../../styles/global';
import * as styles from './styles'; // Importa todos os estilos de styles.js
import { Container, Content, ContentSection } from '../../styles/styles';
import { BackBar, BackButton, ViewContainer, Pagination, ErrorComponent} from '../../styles/global';
import { getUsers, deleteUser } from '../../controllers/userController';
import { SvgSearch } from '../../assets/icons';
import { LuPenSquare, LuTrash2 } from "react-icons/lu";
import { FaChevronRight, FaPlus } from 'react-icons/fa';

const UserList = () => {
    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]); // Estado para usuários filtrados
    const [searchTerm, setSearchTerm] = useState(''); // Estado para o termo de pesquisa
    const [errorMessage, setErrorMessage] = useState('');
    const [currentPage, setCurrentPage] = useState(1); // Página atual
    const itemsPerPage = 10; // Número de usuários por página

    const userAuth = JSON.parse(localStorage.getItem('user')); // Usuário autenticado
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const userList = await getUsers();
                setUsers(userList);
                setFilteredUsers(userList); // Inicialmente, exibe todos os usuários
            } catch (error) {
                setErrorMessage('Erro ao buscar usuários.');
            }
        };

        fetchUsers();
    }, []);

    const handleSearch = (e) => {
        const term = e.target.value.toLowerCase();
        setSearchTerm(term);

        const filtered = users.filter(user =>
            user.nome.toLowerCase().includes(term) ||
            user.cargo.toLowerCase().includes(term) ||
            user.unidade.toLowerCase().includes(term) ||
            user.email.toLowerCase().includes(term)
        );

        setFilteredUsers(filtered); // Atualiza os usuários filtrados
        setCurrentPage(1); // Reseta a página ao buscar um termo
    };

    const handleEdit = (userId) => {
        navigate(`/perfil/editar/${userId}`);
    };

    const handleDelete = async (userId) => {
        if (window.confirm('Tem certeza de que deseja excluir este usuário?')) {
            try {
                await deleteUser(userId);
                setUsers(users.filter(user => user.idusuario !== userId)); // Remove o usuário da lista
                setFilteredUsers(filteredUsers.filter(user => user.idusuario !== userId)); // Remove o usuário filtrado
                alert('Usuário excluído com sucesso.');
            } catch (error) {
                alert('Erro ao excluir o usuário.');
                setErrorMessage('Erro ao excluir o usuário.');
            }
        }
    };

    // Função para calcular a exibição dos usuários com base na página atual
    const indexOfLastUser = currentPage * itemsPerPage;
    const indexOfFirstUser = indexOfLastUser - itemsPerPage;
    const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

    const totalPages = Math.ceil(filteredUsers.length / itemsPerPage); // Número total de páginas

    // Função para trocar de página
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <>
            <Header />
            <GlobalStyle />
            <Container fullWidth direction="column" align="center" justify="center">
                <Container direction="column" align="center" width="100%">
                    <BackBar>
                        <BackButton onClick={() => navigate(-1)}>← Voltar</BackButton>
                    </BackBar>
                    <ViewContainer>
                        <Container justify="space-between" align="center" padding="0">
                            <h2>Lista de Usuários</h2>
                            <Link to="/perfil/novo">
                                <button><FaPlus /> Novo usuário</button>
                            </Link>
                        </Container>

                        {errorMessage && <ErrorComponent message={errorMessage} duration={8000} />}
                        {/* {errorMessage && <styles.ErrorMessage>{errorMessage}</styles.ErrorMessage>} */}

                        <IconInput>
                            <SvgSearch />
                            <input
                                type="text"
                                placeholder="Pesquisar usuários..."
                                value={searchTerm}
                                onChange={handleSearch}
                            />
                        </IconInput>

                        <table>
                            <thead>
                                <tr>
                                    <td>Nome</td>
                                    <td>Unidade</td>
                                    <td>Dados</td>
                                    <td>Ações</td>
                                </tr>
                            </thead>
                            <tbody>
                                {currentUsers.map(user => (
                                    <tr key={user.idusuario}>
                                        <td>
                                            <div className="item-detalhes">
                                                <strong>{user.nome}</strong>
                                                <span>{user.cargo}</span>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="item-detalhes">
                                                <small>{user.unidade}</small>
                                                <span>{user.secao}</span>
                                                <span>{user.setor}</span>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="item-detalhes">
                                                <small>{user.email}</small>
                                                <span>{user.cpf}</span>
                                            </div>
                                        </td>
                                        <td>
                                            <Container padding="0">
                                                <button onClick={() => handleEdit(user.idusuario)} title='Editar usuário'>
                                                    <LuPenSquare />
                                                </button>
                                                <button onClick={() => handleDelete(user.idusuario)} title='Excluir usuário' >
                                                    <LuTrash2 />
                                                </button>
                                            </Container>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        {/* Paginação */}
                        <Pagination>
                        {[...Array(totalPages).keys()].map(page => (
                                <button
                                    key={page + 1}
                                    onClick={() => paginate(page + 1)}
                                    className={page + 1 === currentPage ? 'active' : ''}
                                >
                                    {page + 1}
                                </button>
                            ))}
                        </Pagination>


                    </ViewContainer>
                </Container>
            </Container>
        </>
    );
};

export default UserList;

import styled from "styled-components";
import { Container } from "../../styles/styles";
import {
  PrimaryColor,
  SecondaryColor,
  TextColor,
  TextColorLight,
  TextColorDark,
  BackgroundColor,
  BackgroundColorLight,
  Border,
  Accent,
  Yellow,
  Blue,
  Green,
} from "../../styles/global";
import breakpoints from "../../styles/breakpoints";



export const PremioForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  max-width: 600px;
  background-color: none;
  border-radius: 10px;
  align-items: center;
  margin-top: -60px;

  h2 {
    color: ${TextColorLight};
  }
`;

export const Header = styled.header`
  background: linear-gradient(165deg, ${Blue} 20%, ${Yellow} 0%);
  color: white;
  padding: 14px;
  padding-bottom: 60px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  position: relative;

  .logo {
      width: 80px;
    }

  .headerButtons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .logo {
      width: 50px;
    }

    > button {
      background-color: transparent;

      > svg {
        height: 20px;
      }
    }
  }

  .info {
    display: flex;
    align-items: center;
    gap: 12px;
    width: 100%;

    > svg {
      width: 100px;
      height: 50px;
    }

    .userInfo {
      text-align: left;

      .headerText {
        font-size: 12px;
        font-weight: 300;
      }

      .userName {
        font-size: 16px;
      }

      .userDetails {
        font-size: 15px;
      }
    }

    .saldo {
      width: 80%;
      color: white;
      padding: 10px;
      border: 1px solid #fff;
      border-radius: 10px;
      text-align: center;

      .saldoTitle {
        font-size: 14px;
        font-weight: 300;
      }

      .saldoValue {
        font-size: 20px;
        font-weight: 400;
      }
    }
  }
`;

export const MenuContent = styled.div`
  max-width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  position: absolute;
  z-index: 100;

  > a {
    img {
      max-width: 70px;
    }
  }
`;

export const InputField = styled.input`
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  border: none;
  background-color: ${TextColorLight};
  color: #000;

  &::placeholder {
    color: #000;
  }
`;

export const BackBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px;
  max-width: 100%;
`;

export const BackButton = styled.a`
  display: flex;
  align-items: center;
  color: #white;
  text-decoration: none;
  font-size: 15px;
  width: fit-content;
  cursor: pointer;

  :hover {
    color: ${SecondaryColor};
  }
`;

export const TextArea = styled.textarea`
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  border: none;
  min-height: 130px;
  color: #000;

  &::placeholder {
    color: #000;
  }
`;

export const FileInput = styled.label`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  background-color: ${TextColorLight};
  padding: 10px;
  border-radius: 8px;
  font-size: 14px;
  cursor: pointer;
  justify-content: space-between;

  input {
    display: none;
  }

  img {
    width: 60%;
  }

  p {
    color: ${TextColor};
  }

  svg {
    width: 45px;
    color: ${PrimaryColor};
  }

  .interna {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .interna div {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    width: fit-content;
    gap: 10px;
    padding: 10px 0;
  }
`;

export const DeleteButton = styled.button`
  background-color: ${BackgroundColor};
  color: ${TextColor};
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 18px;
  cursor: pointer;
  display: flex;
  gap: 5px;
  align-items: top;
  justify-content: center;

  &:hover {
    background-color: ${Accent};
  }

  svg {
    width: 25px;
    height: 25px;
  }
`;

export const SubmitButton = styled.button`
  background-color: ${PrimaryColor};
  color: ${TextColorLight};
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 18px;
  cursor: pointer;

  &:hover {
    background-color: ${BackgroundColor};
    color: ${TextColor};
  }
`;

export const RequiredText = styled.p`
  font-size: 12px;
  color: ${TextColorLight}!important;
  margin-top: -15px;
  align-self: flex-start;
`;

export const ErrorMessage = styled.p`
  color: red;
  font-size: 14px;
  margin-top: 10px;
`;

export const Spinner = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #FF9500;
  animation: spin 1s linear infinite;

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;


export const Aviso = styled(Container)`
  background-color: ${BackgroundColorLight};
  p,
  a {
    color: ${TextColorLight};
  }
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: 8px;
  font-size: 14px;
`;

// Index.js

export const PremiosGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  width: 100%;

  @media only screen and (max-width: ${breakpoints.sm}) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const PremioItem = styled.div`
  display: flex;
  flex-direction: column;
  background: #fffdfb;
  color: ${TextColorDark};
  padding: 20px;
  cursor: pointer;
  transition: 0.5s;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  gap: 12px;

  > div {
    display: flex;
    flex-direction: column;
    gap: 4px;

    img {
      width: 100%;
      max-height: 150px;
      object-fit: cover;
      border-radius: 10px;
      margin-bottom: 10px;
    }

    h5 {
      font-weight: 400;
    }

    h5,
    p {
      color: ${TextColorDark};
      text-align: center;
    }

    p {
      font-size: 12px;
      text-align: center;
    }
  }

  .queroButton {
    background-color: #28b4c8;
    color: white;
    border: none;
    width: 100%;
    padding: 5px;
    border-radius: 90px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-self: end;
  }
`;

export const Pagination = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  button {
    background: none;
    border: none;
    color: ${PrimaryColor};
    cursor: pointer;
    font-size: 16px;

    &:hover {
      text-decoration: underline;
    }
  }
`;

// View

export const PremioContainer = styled(Container)`
  gap: 30px;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;

  @media only screen and (max-width: ${breakpoints.md}) {
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 50px;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 80%;
    max-width: 400px;
    border-radius: 10px;
  }

  @media only screen and (max-width: ${breakpoints.md}) {
    width: 100%;
  }
`;

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  gap: 20px;

  @media only screen and (max-width: ${breakpoints.md}) {
    width: 100%;
    justify-content: center;
    align-items: stretch;
  }
`;

export const Price = styled.span`
  font-weight: bold;
`;

export const Description = styled.p`
  color: ${TextColorDark}!important;
`;

export const QuantityContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;

  p {
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 8px;
    color: #000;
  }
`;


export const QuantityControl = styled.div`
  max-width: 60%;
  display: flex;
  align-items: center;
  gap: 10px;
  background: #f7f7f7;
  padding: 10px 6px;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  color: black;
`;

export const QuantityButton = styled.button`
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: black;
`;


export const Quantity = styled.span`
  font-size: 16px;
  color: black;
`;


export const AddToCartButton = styled.button`
   background-color: ${Green};
  padding: 10px 70px;
  border-radius: 30px; 
  border: none;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  cursor: pointer;

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Container, Content, ContentSection } from "../styles/styles";
import {
  TextColorLight,
  TextColorDark,
  ErrorComponent,
  Border,
  Green,
  PrimaryColor,
  BackgroundColor,
  Blue,
  Yellow,
} from "../styles/global";
import coin1 from "../assets/icons/moeda-1.svg";
import coin5 from "../assets/icons/moeda-5.svg";
import coin10 from "../assets/icons/moeda-10.svg";
import coin50 from "../assets/icons/moeda-50.svg";
import coin100 from "../assets/icons/moeda-100.svg";
import { FaRegUserCircle } from "react-icons/fa";
import { IoCloudUploadOutline } from "react-icons/io5";
import { FiXCircle } from "react-icons/fi";

import { getUserHistory, getUserData } from "../controllers/userController";

const Header = styled.header`
  background: linear-gradient(185deg, ${Blue} 28%, ${Yellow} 0%);
  color: white;
  padding: 14px;
  padding-bottom: 28px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 18px;

  .headerText {
    margin-top: 18px;
    text-align: center;
    font-size: 18px;
    font-weight: normal;
  }
`;

const BackButton = styled.a`
  margin-top: 22px;
  display: flex;
  align-items: center;
  color: #fff;
  text-decoration: none;
  font-size: 20px;
  width: fit-content;
`;

const JustificationForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  max-width: 600px;
  padding-block: 20px;
  border-radius: 10px;

  .moeda {
    width: 50px !important;
  }
`;

const Employee = styled(Container)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 30px;

  .employee-name {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    gap: 10px;
    text-align: left;

    h4 {
      color: #000;
      font-size: 14px;
      font-weight: 300;
    }
  }

  .employee-details {
    display: flex;
    flex-direction: column;
    justify-content: start;
    gap: 0px;
    flex: 1;

    p,
    small {
      text-align: end;
      color: #000;
      font-size: 14px;
      width: max-content;
    }

    p {
      font-weight: 500;
    }
    small {
      font-weight: lighter;
    }
  }

  .employee-icon-div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background-color: ${Border};
    text-align: center;
    padding: 10px;
  }
  .employee-icon {
    font-size: 25px;
    color: #444;
  }
`;

const Label = styled.div`
  color: ${PrimaryColor};
  font-size: 18px;
  font-weight: 400;
  text-align: start;
`;

const SelectReason = styled(Container)`
  flex-direction: column;
  text-align: left;
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid ${Border};

  p {
    color: ${TextColorDark};
    padding: 0 5px;
    font-size: 12px;
  }

  select {
    background-color: transparent;
    border: none;
    color: #000;
    font-family: "Roboto", sans-serif;
    &:focus-visible {
      outline: 0px;
    }
  }
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid ${Border};
  min-height: 130px;
  color: #000;
  background-color: transparent;

  &::placeholder {
    color: #000;
  }
`;

const FileInput = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 20px;
  padding: 4px;
  border-radius: 8px;
  border: 1px solid ${Border};
  font-size: 14px;
  cursor: pointer;

  input {
    display: none;
  }

  svg {
    height: 30px;
    color: #000;
  }

  img {
    width: 60%;
    border-radius: 8px;
  }

  .interna {
    background-color: ${BackgroundColor};
    padding-inline: 8px;
    padding-block: 16px;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .interna span {
    color: #000;
    width: 80%;
  }
`;

const SubmitButton = styled.button`
  background-color: ${Green};
  padding: 10px 70px;
  border-radius: 30px;
  border: none;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  cursor: pointer;
  align-self: center;

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const RequiredText = styled.p`
  font-size: 12px;
  color: ${TextColorLight}!important;
  margin-top: -15px;
  align-self: flex-start;
`;

const ErrorMessage = styled.p`
  color: red;
  font-size: 14px;
  margin-top: 10px;
`;

const JustificarEmocao = ({
  emocionador,
  moedas,
  onSubmit,
  onBack,
  onClose,
  errorMessage,
}) => {
  const currentDate = new Date().toISOString().split("T")[0]; // Formata para yyyy-mm-dd

  const [justification, setJustification] = useState("");
  const [selectedReason, setSelectedReason] = useState("");
  const [displayDate, setDisplayDate] = useState("");
  const [isoDate, setIsoDate] = useState(currentDate);
  const [file, setFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);

  const [userData, setUserData] = useState({});
  const [history, setHistory] = useState([]);
  const [error, setError] = useState("");

  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userInfo = await getUserData(user.idusuario);
        setUserData(userInfo);

        const userHistory = await getUserHistory(user.idusuario);
        setHistory(userHistory);
      } catch (error) {
        setError("Erro ao carregar dados.");
      }
    };

    fetchData();
  }, [user.idusuario]);

  useEffect(() => {
    const currentDate = new Date();
    // setDisplayDate(currentDate.toLocaleDateString('pt-BR', {
    //   day: '2-digit',
    //   month: 'long',
    //   year: 'numeric',
    // }));
    // setIsoDate(currentDate.toISOString());
  }, []);

  const handleChange = (e) => setJustification(e.target.value);

  const handleReasonChange = (e) => setSelectedReason(e.target.value);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFile(file);
    setPreviewImage(URL.createObjectURL(file)); // Mostra a imagem selecionada
  };

  const handleDateChange = (e) => setIsoDate(e.target.value);

  // const handleDateChange = (e) => {
  //   const selectedDate = new Date(e.target.value);
  //   setDisplayDate(selectedDate.toLocaleDateString('pt-BR', {
  //     day: '2-digit',
  //     month: 'long',
  //     year: 'numeric',
  //   }));
  //   setIsoDate(selectedDate.toISOString().split('T')[0]); // Data no formato ISO
  // };

  const totalMoedas = Object.keys(moedas).reduce((total, value) => {
    return total + moedas[value] * parseInt(value);
  }, 0);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({
      justification,
      reason: selectedReason,
      date: isoDate,
      file,
    });
  };

  const reasonTexts = {
    Rir: "Quem ri mais vive mais. Torne nosso atendimento caloroso.",
    "Cumprimente sempre":
      "Cumprimente o hóspede com um sorriso, um gesto Tauá e palavras calorosas.",
    "Converse com o hóspede":
      "Aproxime-se e pergunte se ele precisa de algo para se sentir em casa.",
    "O cliente é o rei":
      "Pare tudo o que está fazendo. Dê atenção ao hóspede: resolva o problema, carregue a mala, acompanhe-o até onde ele quer ir, pegue o que ele precisa.",
    "Seja colaborativo":
      "Ajude os colegas e outros setores. Pegue o papel do chão: todos somos responsáveis pela limpeza.",
    "Atenção total, cara alegre e rápido":
      "Trabalhe com excelência e atenção aos detalhes. Mantenha o foco e evite o uso do celular durante o trabalho.",
    "Tauá é uma empresa de oportunidades":
      "Capacite-se e se esforce. Queremos e incentivamos o crescimento interno.",
    "Cante a música 'Amigos para sempre'":
      "Cante alto, com entusiasmo e com um sorriso no rosto.",
    "Trabalhe em prol do resultado":
      "Acompanhe suas metas e seja ético em suas atitudes e tarefas.",
    "Seja feliz":
      "Fazer alguém feliz também nos faz felizes. Tenha humildade, simplicidade, respeito e gratidão.",
    "Hora da Magia": "Atribuição especial do TCF",
    "Programa Anjo": "Atribuição especial do TCF",
    "Premiação Guardiões Mais Tauá": "Atribuição especial do TCF",
    "Premiação Sala de Vendas Mais Tauá": "Atribuição especial do TCF",
    "Emocionador 6 estrelas": "Atribuição especial do TCF",
    "Liderança 6 estrelas": "Atribuição especial do TCF",
    "Conclusão de curso da Unisorriso": "Atribuição especial do TCF",
    "Premiação Mais Citados Pelos Clientes": "Atribuição especial do TCF",
    "Saldo inicial de moedas": "Atribuição especial do TCF",
    "Premiação da Diretoria": "Atribuição especial do TCF",
    Premiação: "Atribuição especial do TCF",
  };

  return (
    <>
      <Header>
        <h1 className="headerText">Emocionar</h1>
        <BackButton onClick={onClose}>
          <FiXCircle />
        </BackButton>
      </Header>
      <Container direction="column" width="100%" padding="10px">
        <ContentSection fullWidth padding="10px">
          <Content padding="0px">
            <JustificationForm onSubmit={handleSubmit}>
              <Label>Emocionador</Label>
              <Employee fullWidth padding="0px">
                <div className="employee-name">
                  <div className="employee-icon-div">
                    <FaRegUserCircle className="employee-icon" />
                  </div>
                  <h4>{emocionador.nome}</h4>
                </div>
                <div className="employee-details">
                  <p>{emocionador.unidade}</p>
                  <small>{emocionador.setor}</small>
                </div>
              </Employee>

              <Label>Moedas</Label>
              <Container padding="0px">
                {moedas["1"] > 0 && (
                  <img src={coin1} alt="1 Moeda" className="moeda" />
                )}
                {moedas["5"] > 0 && (
                  <img src={coin5} alt="5 Moedas" className="moeda" />
                )}
                {moedas["10"] > 0 && (
                  <img src={coin10} alt="10 Moedas" className="moeda" />
                )}
                {moedas["50"] > 0 && (
                  <img src={coin50} alt="50 Moedas" className="moeda" />
                )}
                {moedas["100"] > 0 && (
                  <img src={coin100} alt="100 Moedas" className="moeda" />
                )}
              </Container>

              <Container direction="column" gap="20px" padding="0px">
                <Label>Mandamento</Label>
                <SelectReason>
                  <select
                    value={selectedReason}
                    onChange={handleReasonChange}
                    required
                  >
                    <option value="">Selecione uma razão</option>
                    <option value="Rir">Rir</option>
                    <option value="Cumprimente sempre">
                      Cumprimente sempre
                    </option>
                    <option value="Converse com o hóspede">
                      Converse com o hóspede
                    </option>
                    <option value="O cliente é o rei">O cliente é o rei</option>
                    <option value="Seja colaborativo">Seja colaborativo</option>
                    <option value="Atenção total, cara alegre e rápido">
                      Atenção total, cara alegre e rápido
                    </option>
                    <option value="Tauá é uma empresa de oportunidades">
                      Tauá é uma empresa de oportunidades
                    </option>
                    <option value="Cante a música 'Amigos para sempre'">
                      Cante a música 'Amigos para sempre'
                    </option>
                    <option value="Trabalhe em prol do resultado">
                      Trabalhe em prol do resultado
                    </option>
                    <option value="Seja feliz">Seja feliz</option>
                    {/** Admin only options*/}
                    {user.nivel == 1 ? (
                      <>
                        <option value="Hora da Magia">Hora da Magia</option>
                        <option value="Programa Anjo">Programa Anjo</option>
                        <option value="Premiação Guardiões Mais Tauá">
                          Premiação Guardiões Mais Tauá
                        </option>
                        <option value="Premiação Sala de Vendas Mais Tauá">
                          Premiação Sala de Vendas Mais Tauá
                        </option>
                        <option value="Emocionador 6 estrelas">
                          Emocionador 6 estrelas
                        </option>
                        <option value="Liderança 6 estrelas">
                          Liderança 6 estrelas
                        </option>
                        <option value="Conclusão de curso da Unisorriso">
                          Conclusão de curso da Unisorriso
                        </option>
                        <option value="Premiação Mais Citados Pelos Clientes">
                          Premiação Mais Citados Pelos Clientes
                        </option>
                        <option value="Saldo inicial de moedas">
                          Saldo inicial de moedas
                        </option>
                        <option value="Premiação da Diretoria">
                          Premiação da Diretoria
                        </option>
                        <option value="Premiação">Premiação </option>
                      </>
                    ) : null}
                  </select>

                  {selectedReason && <p>{reasonTexts[selectedReason]}</p>}
                </SelectReason>

              </Container>

              {errorMessage && (
                <ErrorComponent message={errorMessage} duration={8000} />
              )}

              <SubmitButton type="submit">Confirmar</SubmitButton>
            </JustificationForm>
          </Content>
        </ContentSection>
      </Container>
    </>
  );
};

export default JustificarEmocao;

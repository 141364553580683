import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import styled from "styled-components";
import GlobalStyle from "../../styles/global";
import breakpoints from "../../styles/breakpoints";
import placeholderImage from "../../assets/images/placeholder.png";
import { Container, Content, ContentSection } from "../../styles/styles";
import { BackBar, BackButton } from "./styles";
import {
  getPremioById,
  resgatarPremio,
} from "../../controllers/premioController"; // Descomentar quando a API estiver disponível
import {
  PremioContainer,
  ImageContainer,
  DetailsContainer,
  Price,
  Description,
  AddToCartButton,
  QuantityContainer,
  QuantityControl,
  QuantityButton,
  Quantity,
  ErrorMessage,
} from "./styles";
import PremioFooter from "./premio-adm-footer";
import { API_URL } from "../../controllers/api";
import Footer from "../../components/footer";
import { Header } from "./styles";
import logo from "../../assets/icons/logo-banco-da-felicidade.svg";
import { FaRegBell } from "react-icons/fa";
import { FaCircleUser } from "react-icons/fa6";
import { getUserData, getUserHistory } from "../../controllers/userController";
import ErrorComponent from "../../styles/global";
import {Spinner} from "./styles";

const user = JSON.parse(localStorage.getItem("user"));

const PremioDetails = () => {
  const { id } = useParams(); // Captura o ID do prêmio da URL
  const [premio, setPremio] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [errorMessage, setErrorMessage] = useState("");
  const userId = user.idusuario; // ID do usuário logado
  const [userData, setUserData] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      if (user?.idusuario) {
        const userInfo = await getUserData(user.idusuario);
        setUserData(userInfo);
      }
    };

    fetchData();
  }, [user?.idusuario]);

  console.log(userData);

  useEffect(() => {
    
    getPremioById(id)
      .then(setPremio)
      .catch(() => setErrorMessage("Erro ao carregar o prêmio."));
  }, [id]);

  const handleQuantityChange = (amount) => {
    setQuantity((prevQuantity) => Math.max(1, prevQuantity + amount));
  };

  const handleAddToCart = async () => {
    try {
      console.log("Resgatando prêmio", id, userId, quantity);
      await resgatarPremio(id, userId, quantity); // Chama a função do controller
      navigate("/resgate-concluido", { state: { premio, quantity } }); // Redireciona para a página de sucesso
    } catch (error) {
      setErrorMessage(error.message || "Erro ao resgatar o prêmio.");
    }
  };

  if (!premio) {
    return (
      <>
        <GlobalStyle />
        <ContentSection fullWidth height="100vh">
          <Content direction="column" align="center">
            <Spinner />
            <span style={{ color: 'black' }}>Carregando prêmios...</span>
          </Content>
        </ContentSection>
      </>
    );
  }

  return (
    <>
      <GlobalStyle />
      <Header style={{ paddingBottom: "20px" }}>
        <div className="headerButtons">
          <button>
            <FaRegBell />
          </button>
        </div>
        <div className="info">
          <div className="userInfo">
            <BackBar>
              <BackButton onClick={() => navigate(-1)}>← Voltar</BackButton>
            </BackBar>
          </div>
          <div className="saldo">
            <h3 className="saldoTitle">Meu saldo</h3>
            <h2 className="saldoValue">{userData.saldo_moedas}</h2>
          </div>
        </div>
      </Header>
      <Container bgColor="#fff" fullWidth direction="column" align="center" justify="center">
        <Content maxWidth="400px" padding="0px" style={{paddingBlock: "50px"}}>
          <PremioContainer padding="0px">
                <ImageContainer>
                  <img
                    src={API_URL + premio.url_imagem || placeholderImage}
                    alt={premio.nome}
                  />
                </ImageContainer>
                <DetailsContainer>
                  <Container
                    padding="0px"
                    direction="column"
                    gap="20px"
                    style={{ alignItems: "center", textAlign: "center" }}
                  >
                    <div>
                      <h2 style={{ color: "#30B0C7" }}>{premio.nome}</h2>
                      <Description style={{fontSize: '12px', fontFamily: 'Roboto, sans-serif'}}>{premio.descricao}</Description>
                    </div>
                      <Price style={{ color: "#30B0C7" }}>
                          Valor: {premio.valor}
                      </Price>
                  </Container>

                  {/* <ErrorMessage>{errorMessage ? `Erro: ${errorMessage}` : ""}</ErrorMessage>
                    {errorMessage && (
                    <ErrorComponent message={errorMessage} duration={8000} />
                  )} */}


                    <Container
                      direction="column"
                      gap="30px"
                      style={{ alignItems: "center", justifyContent: "center" }}
                    >
                      <QuantityContainer>
                        <p>Quantidade</p>
                        <QuantityControl
                          style={{ display: "flex", alignItems: "center", textAlign: "center" }}
                        >
                          <QuantityButton
                            onClick={() => handleQuantityChange(-1)}
                          >
                            -
                          </QuantityButton>
                          <Quantity>{quantity}</Quantity>
                          <QuantityButton
                            onClick={() => handleQuantityChange(1)}
                          >
                            +
                          </QuantityButton>
                        </QuantityControl>
                      </QuantityContainer>
                      <AddToCartButton onClick={handleAddToCart}>
                        Continuar
                      </AddToCartButton>
                    </Container>
                </DetailsContainer>
              </PremioContainer>
            </Content>
        <Footer />
      </Container>
    </>
  );
};

export default PremioDetails;

import Home from "./pages/home";
import AppRoutes from "./routes";

function App() {
  return (
    <AppRoutes />
  );
}

export default App;

import React from 'react';
import styled from 'styled-components';
import { PrimaryColor, SecondaryColor, TextColor, TextColorLight, BackgroundColor, BackgroundColorLight, Border } from "../styles/global";
import { Link } from 'react-router-dom';
import { logout, isAuthenticated } from '../auth';

import logo from '../assets/icons/logo-banco-da-felicidade.svg';
import homeIcon from '../assets/icons/icon-home.svg';
import settingsIcon from '../assets/icons/icon-settings.svg';
import giftIcon from '../assets/icons/icon-gift.svg';
import profileIcon from '../assets/icons/icon-profile.svg';
import breakpoints from '../styles/breakpoints';

const HeaderContainer = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  background-color: ${SecondaryColor};
  color: ${TextColorLight};
  @media screen and (max-width: ${breakpoints.md}) {
    padding: 15px 20px;
    gap: 10px;
  }
`;

const LogoContainer = styled.div`

  a{
  display: flex;
  align-items: center;
  gap: 10px;
  width: fit-content;
  cursor: pointer;

  img {
    height: 50px;
    width: 50px;
  }

  h1 {
    font-size: 22px;
    margin: 0;
    font-weight: normal;
    color: ${TextColorLight};
  }
}
  @media screen and (max-width: ${breakpoints.sm}) {
    h1{display: none}
  }
`;

const NavContainer = styled.nav`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 50px;

  a {
    display: flex;
    align-items: center;
    color: ${TextColorLight};
    text-decoration: none;
    gap: 5px;
    font-weight: bold;
    width: fit-content;

    img {
      height: 20px;
      width: 20px;
    }
  }

  a:hover {
    text-decoration: underline;
  }
`;

function Header() {
  return (
    <HeaderContainer>
      
      <LogoContainer>
      <Link to="/">
        <img src={logo} alt="Logo Banco da Felicidade" />
        <h1>Banco da Felicidade</h1>
        </Link>
      </LogoContainer>
      

      {isAuthenticated() ? <>
        <NavContainer>
          <Link to="/">
            <img src={homeIcon} alt="Home" title="Home" />
          </Link>
          {/*<a href="/settings">
            <img src={settingsIcon} alt="Configurações" title='Configurações' />
          </a>*/}
          <a href="/premios">
            <img src={giftIcon} alt="Prêmios" title='Prêmios' />
          </a>
          <Link to="/perfil">
            <img src={profileIcon} title='Perfil' alt="Perfil" />
          </Link>
          <a onClick={logout} title='Sair'>
            Sair
          </a>
        </NavContainer>
      </> : null}

    </HeaderContainer>
  );
}

export default Header;
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import GlobalStyle, { IconInput } from "../../styles/global";
import { ErrorMessage, PremiosGrid, PremioItem, Pagination, MenuContent } from "./styles";
import placeholderImage from "../../assets/images/placeholder.png";
import { SvgSearch } from "../../assets/icons";
import { BackBar, BackButton } from "../../styles/global";
import { Container, Content, ContentSection } from "../../styles/styles";
import { getPremios } from "../../controllers/premioController";
import { API_URL } from "../../controllers/api";
import { isDesktop, isMobile, isTablet } from "../../utils";
import logo from "../../assets/icons/logo-banco-da-felicidade.svg";
import { FaRegBell } from "react-icons/fa";
import { FaCircleUser } from "react-icons/fa6";
import { Header} from "./styles";
import { Link } from "react-router-dom";
import iconPrizes from "../../assets/icons/icon-prizes.svg";
import iconHistory from "../../assets/icons/icon-history.svg";
import iconRanking from "../../assets/icons/icon-ranking.svg";
import iconGive from "../../assets/icons/icon-give.svg";
import { getUserData, getUserHistory } from "../../controllers/userController";
import Footer from "../../components/footer";
import {Spinner} from "./styles";

const user = JSON.parse(localStorage.getItem("user"));

const PremioList = () => {
  const [userData, setUserData] = useState({});
  const [moedasDistribuidas, setMoedasDistribuidas] = useState({});
  const [history, setHistory] = useState([]);
  const [premios, setPremios] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    getPremios()
      .then(setPremios)
      .catch(() => setErrorMessage("Erro ao carregar os prêmios."));
  }, []);

  const user = JSON.parse(localStorage.getItem("user")) || {};

  useEffect(() => {
    const fetchData = async () => {
      if (user?.idusuario) {
        const userInfo = await getUserData(user.idusuario);
        setUserData(userInfo);
      }
    };

    fetchData();
  }, [user?.idusuario]);

  console.log(userData);

  const filteredPremios = premios.filter((premio) =>
    premio.nome.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const premiosPerPage = isDesktop() ? 9 : isTablet() ? 10 : 10; // Número de prêmios por página

  const startIndex = (currentPage - 1) * premiosPerPage;
  const selectedPremios = filteredPremios.slice(
    startIndex,
    startIndex + premiosPerPage
  );

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reinicia para a primeira página ao realizar uma busca
  };

  const handleNextPage = () => {
    if (startIndex + premiosPerPage < filteredPremios.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  if (errorMessage || premios.length === 0) {
    return (
      <>
        <GlobalStyle />
        
        <ContentSection fullWidth height="100vh">
          <Content direction="column" align="center">
            <Spinner />
            <span style={{ color: 'black' }}>Procurando prêmios...</span>
          </Content>
        </ContentSection>
      </>
    );
  }

  return (
    <>
      <GlobalStyle />
      <Header>

        <div className="headerButtons">
          <img src={logo} alt="Logo Banco da Felicidade" className="logo" />
          <button>
            <FaRegBell />
          </button>
        </div>
        <div className="info">
          <FaCircleUser />
          <div className="userInfo">
            <h1 className="headerText">Bem-vindo de volta!</h1>
            <h2 className="userName">{userData.nome}</h2>
            
            <p className="userDetails">{userData.unidade}</p>
            <p className="userDetails">{userData.cargo}</p>
          </div>
          <div className="saldo">
            <h3 className="saldoTitle">Meu saldo</h3>
            <h2 className="saldoValue">{userData.saldo_moedas}</h2>
          </div>
        </div>
      </Header>
      {/* {user.nivel !== 0 && user.nivel !== 1  ? (
          <>
            <Link to="/emocionar" className="emocionarSection">
              <button className="emocionarButton">Emocionar</button>
            </Link>
          </>
        ) : null} */}

        <Container
          bgColor="#fff"
          fullWidth
          justify="center"
          align="center"
          direction="row"
          style={{ gap: "20px" }}
          position='relative'
        >
          <MenuContent>
            {user.nivel != 0 ? (
              <>
                <Link to="/dashboard">
                  <img src={iconRanking} alt="Ranking" />
                </Link>
              </>
            ) : null}

            {user.nivel == 1 ? (
              <>
                <Link to="/distribuir-moedas">
                  <img src={iconGive} alt="Destribuir" />
                </Link>
              </>
            ) : null}
            <Link to="/premios">
              <img src={iconPrizes} alt="Prêmios" />
            </Link>
            <Link to="/">
              <img src={iconHistory} alt="Históricos" />
            </Link>
          </MenuContent>
        </Container>

        <Container bgColor="#fff" fullWidth direction="column" align="center" justify="center" padding="0px" >
        <ContentSection fullWidth style={{paddingBlock: '50px'}}>
          <Content style={{gap:'10px'}}>
            <h1 style={{ color: 'black', textAlign: 'left', fontSize: '14px', fontWeight: "400" }}>Premios</h1>
            <IconInput>
              <SvgSearch />
              
              <input
                type="text"
                placeholder="Pesquisar prêmios"
                value={searchTerm}
                onChange={handleSearch}
              />
            </IconInput>

            <PremiosGrid>
              {selectedPremios.map((premio) => (
                <PremioItem
                  key={premio.idpremio}
                  onClick={() => navigate(`/premios/premio/${premio.idpremio}`)}
                >
                  <div>
                    <img
                      src={API_URL + premio.url_imagem || placeholderImage}
                      alt={premio.nome}
                      />
                    <h5>{premio.nome}</h5>
                    <p>
                      Valor <strong>{premio.valor}</strong>
                    </p>
                  </div>
                  <button className="queroButton">Quero</button>
                </PremioItem>
              ))}
            </PremiosGrid>
          </Content>
        </ContentSection>
        <Footer/>
      </Container>
    </>
  );
};

export default PremioList;

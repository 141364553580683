import { API_URL } from './api';

export const getItensSolicitados = async () => {
    const response = await fetch(`${API_URL}/itens-solicitados`);
    return response.json(); // Retorna os itens solicitados da API
};

export const atualizarStatusItem = async (id, status) => {
    const response = await fetch(`${API_URL}/itens-solicitados/${id}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ status }), // Atualiza o status
    });
    return response.json(); // Retorna a resposta da API
};

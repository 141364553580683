import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Container, Content, ContentSection } from '../styles/styles';
import { PrimaryColor, SecondaryColor, TextColor, TextColorLight, TextColorDark, BackgroundColor, BackgroundColorLight, Accent, ErrorComponent } from '../styles/global';
import { BackBar, BackButton } from '../styles/global';
import { SvgUser, SvgCalendar, SvgUpload } from '../assets/icons';

const JustificationForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  max-width: 600px;
  padding: 20px;
  background-color: ${SecondaryColor};
  border-radius: 10px;
  align-items: center;
`;

const Employee = styled(Container)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-self: center;
  background-color: ${BackgroundColor};
  width: fit-content;
  padding: 10px 12px;
  gap: 20px;
  width: 100%;
  margin-bottom: 20px;

  p, h5 { color: ${TextColor}; }
  p, small { font-size: 12px; }
  p { font-weight: 500; }
  small { font-weight: lighter; }

  .employee-name {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    gap: 10px;
    text-align: left;
  }

  .employee-details {
    display: flex;
    flex-direction: column;
    justify-content: start;
    gap: 0px;
    flex: 1;
    text-align: left;
    small { width: max-content; }
  }

  .employee-icon-div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: ${SecondaryColor};
    text-align: center;
    padding: 10px;
  }
  
  .employee-icon {
    font-size: 10px;
    color: ${TextColorLight};
  }
`;

const SelectReason = styled(Container)`
  flex-direction: column;
  text-align: left;
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  background-color: ${TextColorLight};
  

  p{
    color: ${TextColorDark};
    padding: 0 5px;
    font-size: 12px;
  }

    select{
      border: none;
      color: ${TextColor};
      font-weight: 600;
      &:focus-visible{
        outline: 0px;
      }
    }
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  border: none;
  min-height: 130px;
  color: ${TextColor};

  &::placeholder {
    color: ${TextColor};
  }
`;

const DateInput = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: ${TextColorLight};
  padding: 10px;
  border-radius: 8px;
  font-size: 14px;
  justify-content: space-between;

  input {
    //display: none;
    border-radius: 5px;
    border: none;
    padding: 3px;
    color: ${TextColor};
  }

  svg {
    width: 30px;
    color: ${PrimaryColor};
  }
  
  div {
    display: flex;
    justify-content: start;
    align-items: center;
    width: fit-content;
    gap: 10px;
  }
`;

const InputField = styled.input`
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  border: none;
  background-color: ${TextColorLight};
  color: ${TextColor};

  &::placeholder {
    color: ${TextColor};
  }

  &:disabled {
    color: ${TextColorDark};
    background-color: #cacaca;
  }
`;

const FileInput = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 20px;
  background-color: ${TextColorLight};
  padding: 10px;
  border-radius: 8px;
  font-size: 14px;
  cursor: pointer;
  

  input {
    display: none;
  }

  svg {
    width: 45px;
    color: ${PrimaryColor};
  }

  img{
    width: 60%;
    border-radius: 8px;
  }

  .interna {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  
  .interna div {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    width: fit-content;
    gap: 10px;
  }
`;

const SubmitButton = styled.button`
  background-color: ${BackgroundColor};
  color: ${TextColor};
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 18px;
  cursor: pointer;

  &:hover {
    background-color: ${PrimaryColor};
    color: ${TextColorLight};
  }
`;

const RequiredText = styled.p`
  font-size: 12px;
  color: ${TextColorLight}!important;
  margin-top: -15px;
  align-self: flex-start;
`;

const ErrorMessage = styled.p`
  color: red;
  font-size: 14px;
  margin-top: 10px;
`;

const JustificarRemocao = ({ emocionador, moedas, onSubmit, onBack, onClose, errorMessage }) => {
  const currentDate = new Date().toISOString().split('T')[0]; // Formata para yyyy-mm-dd

  const [justification, setJustification] = useState('');
  const [selectedReason, setSelectedReason] = useState('');
  const [isoDate, setIsoDate] = useState(currentDate);
  const [file, setFile] = useState(null);

  useEffect(() => {
    const currentDate = new Date();
    // setDisplayDate(currentDate.toLocaleDateString('pt-BR', {
    //   day: '2-digit',
    //   month: 'long',
    //   year: 'numeric',
    // }));
    // setIsoDate(currentDate.toISOString());
  }, []);

  const handleChange = (e) => setJustification(e.target.value);

  const handleReasonChange = (e) => setSelectedReason(e.target.value);

  const handleDateChange = (e) => setIsoDate(e.target.value);

  // const handleDateChange = (e) => {
  //   const selectedDate = new Date(e.target.value);
  //   setDisplayDate(selectedDate.toLocaleDateString('pt-BR', {
  //     day: '2-digit',
  //     month: 'long',
  //     year: 'numeric',
  //   }));
  //   setIsoDate(selectedDate.toISOString().split('T')[0]); // Data no formato ISO
  // };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({
      justification,
      reason: selectedReason,
      date: isoDate,
      file,
    });
  };

  return (
    <Container direction="column" width="100%">
      <BackBar>
        <BackButton onClick={onBack}>← Voltar</BackButton>
        <BackButton onClick={onClose}>X</BackButton>
      </BackBar>

      <ContentSection fullWidth>
        <Content>
          <JustificationForm onSubmit={handleSubmit}>
            <Employee shadow>
              <div className='employee-name'>
                <div className="employee-icon-div">
                  <SvgUser className="employee-icon" />
                </div>
                <h5>{emocionador.nome}</h5>
              </div>
              <small>{emocionador.email}</small>
              <div className="employee-details">
                <p>{emocionador.unidade}</p>
                <small>{emocionador.setor}</small>
              </div>
            </Employee>

            <Container direction="column" width="80%" gap="20px">
              
              <InputField
                type="text"
                placeholder="Qual o motivo?"
                onChange={handleReasonChange}
                required
              />
              <RequiredText>Obrigatório *</RequiredText>

              <TextArea
                value={justification}
                onChange={handleChange}
                placeholder="Descrição da remoção"
                required
              />
              <RequiredText>Obrigatório *</RequiredText>

              <DateInput>
                <div>
                  <p><strong>Removeu em</strong></p>
                </div>
                <div>
                  <SvgCalendar />
                  {/* <span>{displayDate}</span> */}
                  <input
                    type="date"
                    value={isoDate}
                    onChange={handleDateChange}
                    required
                  />
                </div>
              </DateInput>
              <RequiredText>Obrigatório *</RequiredText>

            </Container>

            {errorMessage && <ErrorComponent message={errorMessage} duration={8000} />}

            <SubmitButton type="submit">Confirmar</SubmitButton>
          </JustificationForm>
        </Content>
      </ContentSection>
    </Container>
  );
};

export default JustificarRemocao;

import React from 'react';
import styled from 'styled-components';
import { Container } from '../styles/styles';
import { PrimaryColor, SecondaryColor, TextColor, TextColorLight, BackgroundColor, BackgroundColorLight, Accent, Green, Border } from '../styles/global';
import { BackBar, BackButton } from '../styles/global';
import { ContentSection, Content } from '../styles/styles';
import sendCoin from '../assets/icons/coin-sucess.svg';


const Sucess = styled(Content)`
    flex-direction: column;
    align-items: center;
    gap: 30px;
    padding: 40px;

    img{
        width: 120px;
        border-radius: 12px;
    }
`;

const SuccessIcon = styled.div`
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${PrimaryColor};
  color: ${TextColorLight};
  border-radius: 50%;
  padding: 10px;
  font-size: 30px;
  margin-bottom: -40px;
  position: relative;
  z-index:1;
  align-self: center;

  /* &:after {
    content: "";
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background: ${Accent};
    position: absolute;
    top: -20px;  
    left: -20px; 
    z-index: -1;
  } */

`;

const InfoCoins = styled.div`
  background-color:#66C3E9;
  color: #fff;
  padding: 15px;
  border-radius: 8px;
  font-size: 18px;
  text-align: center;
`

const InfoContainer100 = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 5px;
    gap: 0;
    background-color: #000
    `;

const InfoContainer50 = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    padding: 5px;
    gap: 0;
 
    `;

const InfoContainer33 = styled.div`
   width: 100%;
        display: flex;
        flex-direction: column;
        padding: 5px;
        gap: 0;

    `;

   

const InfoContainer = styled(Container)`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 10px;
  text-align: left;
  margin-bottom: 20px;
  gap: "15px";
  background-color: #fff;
  border: 1px solid ${Border};
  border-radius: 8px;

  .simpleText {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    color: #000
  }
`;

const CloseButton = styled.button`
    background-color: ${Green};
  padding: 10px 70px;
  border-radius: 30px; 
  border: none;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  cursor: pointer;

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;


function Success({ content, onClose }) {

    return (
        <Container direction="column" width="100%" padding="0px">

            <ContentSection shadow fullWidth direction="column" gap="0px">
                <Sucess>
                    {content}
                    <CloseButton onClick={onClose}>Finalizar</CloseButton>
                </Sucess>
            </ContentSection>
        </Container>
    );
}

export default Success;
export { InfoContainer100, InfoContainer50, InfoContainer33, InfoContainer, CloseButton, InfoCoins };

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Header from '../../components/header';
import GlobalStyle from '../../styles/global';
import * as styles from './styles';
import { Container, Content, ContentSection } from '../../styles/styles';
import { BackBar, BackButton, ErrorComponent } from '../../styles/global';
import { SvgUpload, SvgDelete } from '../../assets/icons';
import { getPremioById, updatePremio, deletePremio } from '../../controllers/premioController';
import { API_URL } from '../../controllers/api';

const EditarPremio = () => {
    const { id } = useParams(); // Captura o ID do prêmio da URL
    const navigate = useNavigate();
    const [title, setTitle] = useState('');
    const [value, setValue] = useState('');
    const [estoque, setEstoque] = useState('');
    const [description, setDescription] = useState('');
    const [image, setImage] = useState(null);
    const [previewImage, setPreviewImage] = useState(null); // Armazena a URL da imagem para visualização
    const [originalImage, setOriginalImage] = useState(null); // Armazena a URL original da imagem
    const [errorMessage, setErrorMessage] = useState('');
    const [notFound, setNotFound] = useState(false);

    useEffect(() => {
        if (id) {
            getPremioById(id)
                .then(data => {
                    if (!data) {
                        setNotFound(true); // Se o prêmio não for encontrado, atualiza o estado
                    } else {
                        setTitle(data.nome);
                        setValue(data.valor);
                        setEstoque(data.estoque);
                        setDescription(data.descricao);
                        setPreviewImage(API_URL + data.url_imagem); // Define a imagem para visualização
                        setOriginalImage(data.url_imagem); // Armazena a URL original da imagem
                    }
                })
                .catch(err => setErrorMessage(err.message))
        }
    }, [id]);

    const handleTitleChange = (e) => setTitle(e.target.value);
    const handleValueChange = (e) => setValue(e.target.value);
    const handleEstoqueChange = (e) => setEstoque(e.target.value);
    const handleDescriptionChange = (e) => setDescription(e.target.value);
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setImage(file);
        setPreviewImage(URL.createObjectURL(file)); // Mostra a imagem selecionada
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        const formData = new FormData();
        formData.append('nome', title);
        formData.append('valor', value);
        formData.append('estoque', estoque);
        formData.append('descricao', description);
        
        // Se houver uma nova imagem, adiciona ao FormData
        if (image) {
            formData.append('url_imagem', image);
        } else {
            formData.append('url_imagem', originalImage); // Mantém o caminho da imagem existente
        }
    

        try {
            await updatePremio(id, formData);
            navigate('/premios');
        } catch (error) {
            setErrorMessage('Erro ao atualizar o prêmio.');
        }
    };

    const handleDelete = async () => {
        if (window.confirm('Tem certeza de que deseja excluir este prêmio?')) {
            try {
                await deletePremio(id);
                navigate('/premios');
            } catch (error) {
                alert('Erro ao excluir o prêmio: ' + error.message);
            }
        }
    };

    if (notFound) {
        return (
            <>
                <Header />
                <GlobalStyle />
                <ContentSection fullWidth height="100vh">
                    <Content direction="column" align="center">
                        <styles.Aviso light>
                            <p>Prêmio não encontrado ou ID inválido</p>
                            <BackButton onClick={() => navigate(-1)}>← Voltar</BackButton>
                            {errorMessage && <styles.ErrorMessage>{errorMessage}</styles.ErrorMessage>}
                        </styles.Aviso>
                    </Content>
                </ContentSection>
            </>
        );
    }

    return (
        <>
            <Header />
            <GlobalStyle />
            <Container fullWidth direction="column" align="center" justify="center">
                <Container direction="column" width="100%">
                    <BackBar>
                        <BackButton onClick={() => navigate(-1)}>← Voltar</BackButton>
                        <BackButton onClick={() => navigate('/')}>X</BackButton>
                    </BackBar>

                    <ContentSection fullWidth>
                        <Content light>
                            <styles.PremioForm onSubmit={handleSubmit}>
                                <h2>Editar: {title}</h2>
                                <Container direction="column" width="80%" gap="20px">
                                    <styles.InputField
                                        type="text"
                                        placeholder="Nome do prêmio"
                                        value={title}
                                        onChange={handleTitleChange}
                                        required
                                    />
                                    <styles.RequiredText>Obrigatório *</styles.RequiredText>

                                    <styles.InputField
                                        type="number"
                                        placeholder="Valor do prêmio"
                                        value={value}
                                        onChange={handleValueChange}
                                        required
                                    />
                                    <styles.RequiredText>Obrigatório *</styles.RequiredText>

                                    <styles.InputField
                                        type="number"
                                        placeholder="Estoque"
                                        value={estoque}
                                        onChange={handleEstoqueChange}
                                        required
                                    />
                                    <styles.RequiredText>Obrigatório *</styles.RequiredText>


                                    <styles.TextArea
                                        placeholder="Descrição do prêmio"
                                        value={description}
                                        onChange={handleDescriptionChange}
                                        required
                                    />
                                    <styles.RequiredText>Obrigatório *</styles.RequiredText>

                                    <styles.FileInput>
                                        <div className='interna'>
                                            <div>
                                                <p><strong>Imagem principal</strong></p>
                                            </div>
                                            <div>
                                                <SvgUpload />
                                                <span>{image ? 'Alterar imagem' : 'Enviar imagem'}</span>
                                                <input type="file" onChange={handleImageChange} required={!previewImage} />
                                            </div>
                                        </div>
                                        {previewImage && <img src={previewImage} alt="Pré-visualização da imagem" />}
                                    </styles.FileInput>
                                    <styles.RequiredText>Obrigatório *</styles.RequiredText>

                                    {/* {errorMessage && <styles.ErrorMessage>{errorMessage}</styles.ErrorMessage>} */}
                                    {errorMessage && <ErrorComponent message={errorMessage} duration={8000} />}

                                    <styles.DeleteButton onClick={handleDelete}>
                                        <SvgDelete />
                                        Excluir Prêmio
                                    </styles.DeleteButton>

                                    <styles.SubmitButton type="submit">Confirmar</styles.SubmitButton>
                                </Container>
                            </styles.PremioForm>
                        </Content>
                    </ContentSection>
                </Container>
            </Container>
        </>
    );
};

export default EditarPremio;

import React, { useState } from "react";
import styled from "styled-components";
import GlobalStyle, { Blue, SecondaryColor } from "../styles/global";
import SelecionarMoedasAdm from "../components/SelecionarMoedasAdm";
import Confirmacao from "../components/Confirmacao";
import { Container } from "../styles/styles";
import { API_URL } from "../controllers/api";
import SelecionarEmocionadorAdm from "../components/SelecionarEmocionadorAdm";

const BackBar = styled(Container)`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 60px;
  max-width: 100%;
`;

function Distribuir({ onClose }) {
  const [step, setStep] = useState(1);
  const [emocionador, setEmocionador] = useState(null);
  const [moedas, setMoedas] = useState([]); // Array de moedas [{ valor: 1, quantidade: 5 }, { valor: 10, quantidade: 2 }]
  const [errorMessage, setErrorMessage] = useState(null);
  const [moedasADistribuir, setMoedasADistribuir] = useState(0);
  const user = JSON.parse(localStorage.getItem("user"));

  const handleSelectEmocionador = (selectedEmocionador) => {
    setEmocionador(selectedEmocionador);

    const moedas = selectedEmocionador.moedas_a_distribuir
      ? JSON.parse(selectedEmocionador.moedas_a_distribuir)
      : 0;
    setMoedasADistribuir(moedas);

    localStorage.setItem("moedasADistribuir", JSON.stringify(moedas));

    setStep(2);
  };
  const handleConfirmMoedas = async (moedas) => {
    setMoedas(moedas);

    try {
      const response = await fetch(`${API_URL}/distribuirMoedas`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          distribuidorId: user.idusuario,
          receptorId: emocionador.id,
          moedas,
        }),
      });

      const result = await response.json();

      if (response.ok) {
        setStep(3); 
      } else {
        setErrorMessage(
          result.mensagem || "Ocorreu um erro ao enviar os dados."
        );
      }
    } catch (error) {
      setErrorMessage(`Erro de comunicação com a API: ${error.message}`);
    }
  };

  const handleBack = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const handleClose = () => {
    window.location.href = "/";
  };

  return (
    <>
      <GlobalStyle />
      <Container
        bgColor="#fff"
        fullWidth
        direction="column"
        align="center"
        justify="center"
        padding="0px"
      >
        {step === 1 && (
          <SelecionarEmocionadorAdm
            onSelect={handleSelectEmocionador}
            onClose={handleClose}
          />
        )}
        {step === 2 && (
          <SelecionarMoedasAdm
            emocionador={emocionador}
            onConfirm={handleConfirmMoedas}
            onBack={handleBack}
            errorMessage={errorMessage}
            onClose={handleClose}
          />
        )}
        {step === 3 && (
          <Confirmacao
            emocionador={emocionador}
            moedas={moedas}
            onClose={handleClose}
          />
        )}
      </Container>
    </>
  );
}

export default Distribuir;

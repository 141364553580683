import { useState } from "react";
import styled from "styled-components";
import ListaEmocionador from "../../components/list";
import UserRemove from "../../components/RemoverUser";
import GlobalStyle, { Container } from "../../styles/global";
import ConfirmacaoRemoverUser from "../../components/ConfirmacaoRemoverUser";
import { deleteUser } from "../../controllers/userController";
import { toast } from "react-toastify";

const BackBar = styled(Container)`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 60px;
  max-width: 100%;
`;

function RemoverUser({ onClose }) {
  const [step, setStep] = useState(1);
  const [selectedUser, setSelectedUser] = useState({});

  const handleSelectEmocionador = (selectedEmocionador) => {
    setSelectedUser(selectedEmocionador);
    setStep(2);
  };

  const handleBack = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const handleClose = () => {
    window.location.href = "/";
  };

  const handleDeleteUser = async (id) => {
    try {
      await deleteUser(id);
      setStep(3); 
    } catch (error) {
  
      toast.error("Erro ao excluir o usuário.");
    }
  };


  return (
    <>
      <GlobalStyle />
      <Container
        bgColor="#fff"
        fullWidth
        direction="column"
        align="center"
        padding="0px"
        height="100%"
      >
        {step === 1 && (
          <ListaEmocionador
            onSelect={handleSelectEmocionador}
            onClose={handleClose}
          />
        )}
        {step === 2 && <UserRemove emocionador={selectedUser} handleDelete={handleDeleteUser}/>}

        {step === 3 && <ConfirmacaoRemoverUser onClose={handleClose} />}
      </Container>
    </>
  );
}

export default RemoverUser;
import React from 'react';
import { Navigate } from 'react-router-dom';
import { isAuthenticated, getUserLevel } from './auth';

const ProtectedRoute = ({ element: Element, levelRequired = 0 }) => {
    if (!isAuthenticated()) {
        return <Navigate to="/login" replace />;
    }
    
    if (getUserLevel() < levelRequired) {
        return <Navigate to="/" replace />;
    }

    return <Element />;
};

export default ProtectedRoute;

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import GlobalStyle, { Accent } from "../styles/global";
import Header from "../components/header";
import { formatDate, formatTime } from '../utils'; // Funções de formatação
import { getItensSolicitados, atualizarStatusItem } from '../controllers/itemController'; // API controllers
import { FaCheckCircle } from "react-icons/fa";
import placeholderImage from '../assets/images/placeholder.png';
import { API_URL } from '../controllers/api';
import { BackBar, BackButton, ViewContainer } from '../styles/global';
import { ContentSection, Container } from '../styles/styles';
import breakpoints from '../styles/breakpoints';


const TableData = styled.td`
  img {
    width: 60px;
    border-radius: 8px;
  }
`;

const StatusButton = styled.button`
  padding: 5px 8px;
  font-size: 11px;
  border: none;
  border-radius: 5px;
`;

function ItensSolicitados() {
    const [itensSolicitados, setItensSolicitados] = useState([]);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getItensSolicitados(); // Buscar itens solicitados da API
                setItensSolicitados(response);  // Define o estado com os itens solicitados
            } catch (error) {
                setError('Erro ao carregar itens solicitados.');
            }
        };
        fetchData();
    }, []);

    // Função para atualizar o status de um item para "Entregue"
    const handleStatusEntregue = async (id) => {
        window.confirm('Deseja definir este item como entregue?');
        try {
            await atualizarStatusItem(id, 'Entregue'); // Atualiza o status via API
            const updatedItens = itensSolicitados.map((item) =>
                item.idresgate === id ? { ...item, status: 'Entregue' } : item
            );
            setItensSolicitados(updatedItens); // Atualiza o estado para refletir a mudança
            alert('Status atualizado com sucesso.');
        } catch (error) {
            setError('Erro ao atualizar o status do item.');
        }
    };

    return (
        <>
            <Header />
            <GlobalStyle />
            <Container fullWidth align="center" justify="center">
                <ContentSection fullWidth direction="column">

                    <BackBar>
                        <BackButton onClick={() => navigate(-1)}>← Voltar</BackButton>
                    </BackBar>

            <ViewContainer>
                <h2>Itens Solicitados</h2>
                {error && <p>{error}</p>}

                <table>
                    <thead>
                        <tr>
                            <td>Prêmio</td>
                            <td>Emocionador</td>
                            <td>Data</td>
                            <td>Status</td>
                            <td>Ação</td>
                        </tr>
                    </thead>
                    <tbody>
                        {itensSolicitados.length > 0 ? (
                            itensSolicitados.map((item) => (
                                <tr key={item.idresgate}>
                                    <TableData>
                                        <Container width="fit-content">
                                        <img src={API_URL + item.url_imagem || placeholderImage} alt={item.premio_nome} />
                                            <Container direction="column">
                                                <p><strong>{item.premio_nome}</strong> x{item.qtd}</p>
                                                <p><strong>{item.valor_moeda_resgatado}</strong></p>
                                            </Container>
                                        </Container>
                                    </TableData>
                                    <TableData>
                                        <Container direction="column">
                                        <p><strong>{item.usuario_nome}</strong></p>
                                        <small><strong>{item.usuario_unidade}</strong></small>
                                        <small><span>{item.usuario_cargo}</span></small>
                                        <small><span>{item.usuario_secao}</span></small>
                                        <small><span>{item.usuario_setor}</span></small>
                                    </Container>
                                    </TableData>
                                    <TableData>
                                        <div className="item-detalhes-data">
                                            <small>{formatDate(item.data_resgate)}</small>
                                            <small>{formatTime(item.data_resgate)}</small>
                                        </div>
                                    </TableData>
                                    <TableData>{item.status}</TableData>
                                    <TableData>
                                        {item.status !== 'entregue' ? (
                                            <StatusButton
                                                onClick={() => handleStatusEntregue(item.idresgate)}
                                            >
                                                Definir como Entregue
                                            </StatusButton>
                                        ) : (
                                            <FaCheckCircle color="#4CAF50" />
                                        )}
                                    </TableData>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <TableData colSpan="6">Nenhum item solicitado encontrado.</TableData>
                            </tr>
                        )}
                    </tbody>
                </table>
            </ViewContainer>
            </ContentSection>
            </Container>
        </>
    );
}

export default ItensSolicitados;

import { useState } from "react";
import { useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import styled from "styled-components";
import ListaPremio from "../../components/listPrize";
import { deletePremio } from "../../controllers/premioController";
import GlobalStyle, { Container } from "../../styles/global";
import PremioRemove from "../../components/RemoverPremio";
import ConfirmacaoRemoverPremio from "../../components/ConfirmacaoRemoverPremio";

const BackBar = styled(Container)`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 60px;
  max-width: 100%;
`;

function RemoverPremio({ onClose }) {
  const [step, setStep] = useState(1);
  const [selectedPremio, setSelectedPremio] = useState({});
  const { id } = useParams();

  const handleSelectPremio = (selectedPremio) => {
    setSelectedPremio(selectedPremio);
    setStep(2);
  };

  const handleBack = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const handleClose = () => {
    window.location.href = "/";
  };

  const handleDelete = async () => {
    try {
      await deletePremio(id);
      setStep(3); 
    } catch (error) {
  
      toast.error("Erro ao excluir o prêmio.");
    }
  };


  return (
    <>
      <GlobalStyle />
      <ToastContainer />
      <Container
        bgColor="#fff"
        fullWidth
        direction="column"
        align="center"
        padding="0px"
        height="100%"
      >
        {step === 1 && (
          <ListaPremio onSelect={handleSelectPremio} onClose={handleClose} />
        )}
        {step === 2 && (
          <PremioRemove premio={selectedPremio} handleDelete={handleDelete} />
        )}
        {step === 3 && <ConfirmacaoRemoverPremio onClose={handleClose} />}
      </Container>
    </>
  );
}

export default RemoverPremio;

// src/controllers/premioController.js
import { API_URL } from './api.js';

export const getPremios = async (id) => {
    try {
        const response = await fetch(`${API_URL}/premios`);
        if (!response.ok) {
            throw new Error('Erro ao buscar prêmio');
        }
        return await response.json();
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const getPremioById = async (id) => {
    try {
        const response = await fetch(`${API_URL}/premios/${id}`);
        if (!response.ok) {
            throw new Error('Erro ao buscar prêmio');
        }
        return await response.json();
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const createPremio = async (premioData) => {
    try {
        const response = await fetch(`${API_URL}/premios`, {
            method: 'POST',
            body: premioData,
        });
        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || 'Erro ao criar prêmio');
        }
        return await response.json();
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const updatePremio = async (id, formData) => {
    try {
        const response = await fetch(`${API_URL}/premios/${id}`, {
            method: 'PUT',
            body: formData, // Envia o FormData diretamente
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.mensagem || 'Erro ao atualizar prêmio.');
        }

        return await response.json();
    } catch (error) {
        console.error(error);
        throw error;
    }
};


export const deletePremio = async (id) => {
    try {
        const response = await fetch(`${API_URL}/premios/${id}`, {
            method: 'DELETE',
        });
        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || 'Erro ao excluir prêmio');
        }
        return await response.json();
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const resgatarPremio = async (premioId, userId, quantidade) => {
    const response = await fetch(`${API_URL}/resgatar-premio`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            id_premio: premioId, // Atualizado para coincidir com a API
            id_usuario: userId, // Atualizado para coincidir com a API
            quantidade,
        }),
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.mensagem || 'Erro ao resgatar o prêmio.'); // Atualizado para coincidir com a resposta da API
    }

    return await response.json();
};

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/header";
import GlobalStyle from "../../styles/global";
import * as styles from "./styles";
import { Container, Content, ContentSection } from "../../styles/styles";
import { BackBar, BackButton, ErrorComponent } from "../../styles/global";
import { SvgUser } from "../../assets/icons";
import { createUser } from "../../controllers/userController"; // Função que vamos criar
import {
  Employee,
  Form,
  InputField,
  SelectField,
  RequiredText,
  ErrorMessage,
  SubmitButton,
  Aviso,
  Label
} from "./styles";
import Inputmask from "inputmask";
import { ToastContainer, toast } from "react-toastify";

const UserAdd = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();

  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");
  const [confirmarSenha, setConfirmarSenha] = useState("");
  const [cpf, setCpf] = useState("");
  const [cargo, setCargo] = useState("");
  const [unidade, setUnidade] = useState("");
  const [secao, setSecao] = useState("");
  const [setor, setSetor] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    Inputmask("999.999.999-99").mask(document.getElementById("cpf"));
  }, []);

  const removeMaskCPF = (cpf) => {
    return cpf.replace(/\D/g, "");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (senha !== confirmarSenha) {
      toast.error("As senhas não conferem.");
      return;
    }

    const cpfSemMascara = removeMaskCPF(cpf);

    const formData = {
      nome,
      email,
      senha,
      cpfSemMascara,
      cargo,
      unidade,
      secao,
      setor,
    };

    try {
      await createUser(formData);
      toast.success("Usuário criado com sucesso.");
     // navigate("/");
    } catch (error) {
      toast.error("Erro ao criar o usuário: " + error.mensagem);
    }
  };

  return (
    <>
      <ToastContainer />
      <GlobalStyle />
      <Container
        style={{
          background: `linear-gradient(10deg, #34C759 20%, #00A565 0%)`,
        }}
        fullWidth
        height="100%"
        direction="column"
        align="center"
        padding="0px"
      >
        <Container fullWidth direction="column" gap="40px" >
          <BackBar>
            <BackButton onClick={() => navigate(-1)}>← Voltar</BackButton>
            <BackButton onClick={() => navigate("/")}>X</BackButton>
          </BackBar>

          <ContentSection fullWidth padding="0px">
            <Content light sx={{paddingTop: '40px', overflowY: 'auto'}} padding="0px">
              <Form onSubmit={handleSubmit}>
                <p>Dados do Emocionador</p>
                <Container fullWidth direction="column" gap="18px" padding="20px">
                  <Label htmlFor="nome">Nome</Label>
                  <InputField
                    type="text"
                    placeholder="Nome"
                    value={nome}
                    onChange={(e) => setNome(e.target.value)}
                    required
                  />

                  <Label htmlFor="cpf">CPF</Label>
                  <InputField
                    type="text"
                    placeholder="CPF"
                    id="cpf"
                    value={cpf}
                    onChange={(e) => setCpf(e.target.value)}
                    required
                  />

                  <Label htmlFor="email">E-mail</Label>
                  <InputField
                    type="email"
                    placeholder="Email do usuário"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />

                  <Label htmlFor="password">Nova senha</Label>
                  <InputField
                    type="password"
                    placeholder="Senha"
                    value={senha}
                    onChange={(e) => setSenha(e.target.value)}
                    required
                  />

                  <Label htmlFor="confirm">Confirmar senha</Label>
                  <InputField
                    type="password"
                    placeholder="Confirmar Senha"
                    value={confirmarSenha}
                    onChange={(e) => setConfirmarSenha(e.target.value)}
                    required
                  />


                  <p>Onde emociona?</p>

                  <SelectField
                    value={unidade}
                    onChange={(e) => setUnidade(e.target.value)}
                    required
                  >
                    <option value="">Unidade</option>
                    <option value="Holding">Holding</option>
                    <option value="Alegro">Alegro</option>
                    <option value="Alexânia">Alexânia</option>
                    <option value="Araxá">Araxá</option>
                    <option value="Atibaia">Atibaia</option>
                    <option value="Caeté">Caeté</option>
                  </SelectField>


                  <SelectField
                    value={cargo}
                    onChange={(e) => setCargo(e.target.value)}
                    required
                  >
                    <option value="">Cargo</option>
                    <option value="Membro da Diretoria">
                      Membro da Diretoria
                    </option>
                    <option value="Gerente Geral">Gerente Geral</option>
                    <option value="Gerente Operacional">
                      Gerente Operacional
                    </option>
                    <option value="Gerente Corporativo">
                      Gerente Corporativo
                    </option>
                    <option value="Gestor">Gestor</option>
                    <option value="Coordenador Corporativo">
                      Coordenador Corporativo
                    </option>
                    <option value="Coordenador">Coordenador</option>
                    <option value="Supervisor">Supervisor</option>
                    <option value="Colaborador">Colaborador</option>
                  </SelectField>

                  
                  <InputField
                    type="text"
                    placeholder="Seção"
                    value={secao}
                    onChange={(e) => setSecao(e.target.value)}
                    required
                  />

                  <InputField
                    type="text"
                    placeholder="Setor"
                    value={setor}
                    onChange={(e) => setSetor(e.target.value)}
                    required
                  />

                  {/* {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
                      {errorMessage && (
                    <ErrorComponent message={errorMessage} duration={8000} />
                  )}
 */}

                  <SubmitButton
                    type="submit"
                    style={{ backgroundColor: "#FFCC00", alignSelf: 'center' }}
                  >
                    Confirmar
                  </SubmitButton>
                </Container>
              </Form>
            </Content>
          </ContentSection>
        </Container>
      </Container>
    </>
  );
};

export default UserAdd;

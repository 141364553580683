import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import GlobalStyle, { TextColorLight, TextColor } from '../styles/global';
import Header from '../components/header';
import { Container } from '../styles/styles';
import { formatDate, formatTime, sumMoedas } from '../utils'; // Importando as funções utilitárias
import { getUserHistory, getUserData } from '../controllers/userController';
import { getMoedasAtribuidas, getMoedasAtribuidasId, getDistribuicoesMoedas, getResgates } from '../controllers/dashboardController';
import { BackBar, BackButton, ViewContainer } from '../styles/global';
import { useNavigate } from 'react-router-dom';

import logo from '../assets/icons/logo-banco-da-felicidade.svg';
import giftIcon from '../assets/icons/icon-gift.png';

import coin1 from '../assets/icons/moeda-1.svg';
import coin5 from '../assets/icons/moeda-5.svg';
import coin10 from '../assets/icons/moeda-10.svg';
import coin50 from '../assets/icons/moeda-50.svg';
import coin100 from '../assets/icons/moeda-100.svg';

// Estilos para as abas
const TabsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 20px;
  overflow-x: auto;
`;

const TabButton = styled.button`
  background-color: ${(props) => (props.active ? '#F59D14' : '#fff2e0')};
  color: ${(props) => (props.active ? TextColorLight : TextColor)};
  padding: 10px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background-color: #F59D14;
    color: ${(props) => (props.active ? '' : TextColorLight)};
  }
`;

const TabContent = styled.div`
  display: ${(props) => (props.active ? 'block' : 'none')};
  overflow-x: auto;
`;

function Historicos() {
    const [activeTab, setActiveTab] = useState(1);
    const [historicoPessoal, setHistoricoPessoal] = useState([]);
    const [emocaoMoedas, setEmocaoMoedas] = useState([]);
    const [emocaoMoedasUsuario, setEmocaoMoedasUsuario] = useState([]);
    const [distribuicaoMoedas, setDistribuicaoMoedas] = useState([]);
    const [distribuicaoMoedasUsuario, setDistribuicaoMoedasUsuario] = useState([]);
    const [resgates, setResgates] = useState([]);
    const user = JSON.parse(localStorage.getItem('user'));
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const userHistory = await getUserHistory(user.idusuario);
                setHistoricoPessoal(userHistory);

                const allMoedas = await getMoedasAtribuidas();
                setEmocaoMoedas(allMoedas);

                const userMoedas = await getMoedasAtribuidasId(user.idusuario);
                setEmocaoMoedasUsuario(userMoedas);

                const allDistribuicoes = await getDistribuicoesMoedas();
                const manualDistribuicoes = allDistribuicoes.filter((dist) => dist.periodo === 'manual');
                setDistribuicaoMoedas(manualDistribuicoes);

                const userDistribuicoes = manualDistribuicoes.filter((dist) => dist.id_distribuidor === user.idusuario);
                setDistribuicaoMoedasUsuario(userDistribuicoes);

                const resgatesData = await getResgates();
                setResgates(resgatesData);

            } catch (error) {
                console.error('Erro ao carregar os dados:', error);
            }
        };

        fetchData();
    }, [user.idusuario]);

    return (
        <>
            <Header />
            <GlobalStyle />
            <Container fullWidth direction="column" align="center" justify="center">
                <Container direction="column" align="center" width="100%">
                    <BackBar>
                        <BackButton onClick={() => navigate(-1)}>← Voltar</BackButton>
                    </BackBar>
                    <ViewContainer align="left">
                        <h2>Históricos</h2>
                        <TabsContainer>
                            <TabButton active={activeTab === 1} onClick={() => setActiveTab(1)}>Meu Histórico</TabButton>
                            <TabButton active={activeTab === 3} onClick={() => setActiveTab(3)}>Minhas Emoções</TabButton>
                            <TabButton active={activeTab === 5} onClick={() => setActiveTab(5)}>Minhas Distribuições</TabButton>
                            <TabButton active={activeTab === 2} onClick={() => setActiveTab(2)}>Todas Emoções</TabButton>
                            <TabButton active={activeTab === 4} onClick={() => setActiveTab(4)}>Todas Distribuições</TabButton>
                            <TabButton active={activeTab === 6} onClick={() => setActiveTab(6)}>Resgates de Prêmios</TabButton>
                        </TabsContainer>

                        {/* Histórico Pessoal */}
                        <TabContent active={activeTab === 1}>
                            <h3>Meu Histórico</h3>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Tipo</th>
                                        <th>Data</th>
                                        <th>Moedas</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {historicoPessoal.map((item, index) => (
                                        <tr key={index}>
                                            <td>
                                                <div className="item-type">
                                                    {item.type === 'resgate' ? <img src={giftIcon} alt={item.type} /> : <img src={logo} alt={item.type} />}
                                                    <h5>{item.nome} <span>{item.type === 'resgate' ? ` x ${item.qtd}` : ''}</span></h5>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="item-time-date">
                                                    <small>{formatTime(item.date)}</small>
                                                    <small>{formatDate(item.date)}</small>
                                                </div>
                                            </td>
                                            <td>
                                                {item.type === 'credito' ? `+${sumMoedas(item.amount)}` : `-${item.amount}`}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </TabContent>

                        {/* Emoções de Moedas do Sistema */}
                        <TabContent active={activeTab === 2}>
                            <h3>Todas Emoções do Sistema</h3>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Motivo</th>
                                        <th>Data Emoção</th>
                                        <th>Distribuidor</th>
                                        <th>Emocionador</th>
                                        <th>Data Criação</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {emocaoMoedas.map((moeda, index) => (
                                        <tr key={index}>
                                            <td><div className="item-type"><img src={logo} /><h5>{moeda.motivo}</h5></div></td>
                                            <td><small>{formatDate(moeda.criacao)}</small></td>
                                            <td><div className="item-detalhes">
                                                <small><b>{moeda.distribuidor}</b></small>
                                                <span>{moeda.distribuidorUnidade}</span>
                                            </div></td>
                                            <td><div className="item-detalhes">
                                                <small>{moeda.emocionador}</small>
                                                <span>{moeda.emocionadorUnidade}</span>
                                            </div></td>
                                            <td><small>{formatDate(moeda.data_emocao)}</small></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </TabContent>

                        {/* Emoções de Moedas do Usuário */}
                        <TabContent active={activeTab === 3}>
                            <h3>Minhas Emoções</h3>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Motivo</th>
                                        <th>Data Emoção</th>
                                        <th>Distribuidor</th>
                                        <th>Emocionador</th>
                                        <th>Data Criação</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {emocaoMoedasUsuario.map((moeda, index) => (
                                        <tr key={index}>
                                            <td><div className="item-type"><img src={logo} /><h5>{moeda.motivo}</h5></div></td>
                                            <td><small>{formatDate(moeda.criacao)}</small></td>
                                            <td><div className="item-detalhes">
                                                <small><b>{moeda.distribuidor}</b></small>
                                                <span>{moeda.distribuidorUnidade}</span>
                                            </div></td>
                                            <td><div className="item-detalhes">
                                                <small>{moeda.emocionador}</small>
                                                <span>{moeda.emocionadorUnidade}</span>
                                            </div></td>
                                            <td><small>{formatDate(moeda.data_emocao)}</small></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </TabContent>

                        {/* Distribuições de Moedas do Sistema */}
                        <TabContent active={activeTab === 4}>
                            <h3>Distribuições de Moedas do Sistema</h3>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Moedas Distribuídas</th>
                                        <th>Distribuidor</th>
                                        <th>Emocionador</th>
                                        <th>Data</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {distribuicaoMoedas.map((dist, index) => (
                                        <tr key={index}>
                                            <td><div className="item-coins">
                                                {(() => {
                                                    const MoedasDistribuidas = JSON.parse(dist.moedas_distribuidas);

                                                    return (
                                                        <>
                                                            {MoedasDistribuidas["1"] > 0 && (
                                                                <>
                                                                    <img src={coin1} alt="1 Moeda" /> {MoedasDistribuidas["1"]}
                                                                </>
                                                            )}
                                                            {MoedasDistribuidas["5"] > 0 && (
                                                                <>
                                                                    <img src={coin5} alt="5 Moedas" /> {MoedasDistribuidas["5"]}
                                                                </>
                                                            )}
                                                            {MoedasDistribuidas["10"] > 0 && (
                                                                <>
                                                                    <img src={coin10} alt="10 Moedas" /> {MoedasDistribuidas["10"]}
                                                                </>
                                                            )}
                                                            {MoedasDistribuidas["50"] > 0 && (
                                                                <>
                                                                    <img src={coin50} alt="50 Moedas" /> {MoedasDistribuidas["50"]}
                                                                </>
                                                            )}
                                                            {MoedasDistribuidas["100"] > 0 && (
                                                                <>
                                                                    <img src={coin100} alt="100 Moedas" /> {MoedasDistribuidas["100"]}
                                                                </>
                                                            )}
                                                        </>
                                                    );
                                                })()}
                                            </div>
                                            </td>
                                            <td><div className="item-detalhes">
                                                <small><b>{dist.distribuidor}</b></small>
                                                <span>{dist.distribuidorUnidade}</span>
                                            </div></td>
                                            <td><div className="item-detalhes">
                                                <small>{dist.emocionador}</small>
                                                <span>{dist.emocionadorUnidade}</span>
                                            </div></td>
                                            <td><small>{formatDate(dist.data_atribuicao)}</small></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </TabContent>

                        {/* Distribuições de Moedas do Usuário */}
                        <TabContent active={activeTab === 5}>
                            <h3>Minhas Distribuições de Moedas</h3>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Moedas Distribuídas</th>
                                        <th>Distribuidor</th>
                                        <th>Emocionador</th>
                                        <th>Data</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {distribuicaoMoedasUsuario.map((dist, index) => (
                                        <tr key={index}>
                                            <td><div className="item-coins">
                                                {(() => {
                                                    const MoedasDistribuidas = JSON.parse(dist.moedas_distribuidas);

                                                    return (
                                                        <>
                                                            {MoedasDistribuidas["1"] > 0 && (
                                                                <>
                                                                    <img src={coin1} alt="1 Moeda" /> {MoedasDistribuidas["1"]}
                                                                </>
                                                            )}
                                                            {MoedasDistribuidas["5"] > 0 && (
                                                                <>
                                                                    <img src={coin5} alt="5 Moedas" /> {MoedasDistribuidas["5"]}
                                                                </>
                                                            )}
                                                            {MoedasDistribuidas["10"] > 0 && (
                                                                <>
                                                                    <img src={coin10} alt="10 Moedas" /> {MoedasDistribuidas["10"]}
                                                                </>
                                                            )}
                                                            {MoedasDistribuidas["50"] > 0 && (
                                                                <>
                                                                    <img src={coin50} alt="50 Moedas" /> {MoedasDistribuidas["50"]}
                                                                </>
                                                            )}
                                                            {MoedasDistribuidas["100"] > 0 && (
                                                                <>
                                                                    <img src={coin100} alt="100 Moedas" /> {MoedasDistribuidas["100"]}
                                                                </>
                                                            )}
                                                        </>
                                                    );
                                                })()}
                                            </div>
                                            </td>
                                            <td><div className="item-detalhes">
                                                <small><b>{dist.distribuidor}</b></small>
                                                <span>{dist.distribuidorUnidade}</span>
                                            </div></td>
                                            <td><div className="item-detalhes">
                                                <small>{dist.emocionador}</small>
                                                <span>{dist.emocionadorUnidade}</span>
                                            </div></td>
                                            <td><small>{formatDate(dist.data_atribuicao)}</small></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </TabContent>

                        {/* Resgates de Prêmios */}
                        <TabContent active={activeTab === 6}>
                            <h3>Resgates de Prêmios</h3>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Prêmio</th>
                                        <th>Usuário</th>
                                        <th>Data</th>
                                        <th>Status</th>
                                        <th>Valor resgatado</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {resgates.map((resgate, index) => (
                                        <tr key={index}>
                                            <td><div className="item-type">
                                                <img src={giftIcon} />
                                                <h5>{resgate.nome} <span> x{resgate.qtd}</span></h5>
                                            </div></td>
                                            <td><div className="item-detalhes">
                                                <small>{resgate.usuario}</small>
                                                <span>{resgate.unidade}</span>
                                            </div></td>
                                            <td><div className="item-detalhes-data">
                                                <small>{formatDate(resgate.criacao)}</small>
                                                <small>{formatTime(resgate.criacao)}</small>
                                            </div></td>
                                            <td><small>{resgate.status}</small></td>
                                            <td>{resgate.valor_moeda_resgatado}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </TabContent>
                    </ViewContainer >
                </Container >
            </Container >
        </>
    );
}

export default Historicos;

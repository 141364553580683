import React from "react";
import styled from "styled-components";
import {  TextColorLight} from "../styles/global";
import { Link } from "react-router-dom";
import { logout, isAuthenticated } from "../auth";

import homeIcon from "../assets/icons/icon-home.svg";
import settingsIcon from "../assets/icons/icon-settings.svg";
import giftIcon2 from "../assets/icons/icon-gift2.svg";
import profileIcon from "../assets/icons/icon-profile.svg";
import breakpoints from "../styles/breakpoints";
import logouticon from "../assets/icons/logout-icon.svg";
const FooterContainer = styled.footer`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  background-color: #f3f3f3;
  color: ${TextColorLight};
  position: fixed;
  bottom: 0;
  width: 100%;

  @media screen and (max-width: ${breakpoints.md}) {
    padding: 15px 20px;
    gap: 5px;
  }
`;

const LogoContainer = styled.div`
  a {
    display: flex;
    align-items: center;
    gap: 10px;
    width: fit-content;
    cursor: pointer;
    color: #000 img {
      height: 50px;
      width: 50px;
    }

    h1 {
      font-size: 22px;
      margin: 1;
      font-weight: normal;
      color: ${TextColorLight};
    }
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    h1 {
      display: none;
    }
  }
`;

const NavContainer = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap:8px;

  a {
    display: flex;
    align-items: center;
    color: ${TextColorLight};
    text-decoration: none;
    gap: 6px;
    font-weight: bold;
    width: fit-content;

    img {
      height: 22px;
      width: 22px;
    }
  }

  a:hover {
    text-decoration: underline;
  }
`;

function Footer() {
  return (
    <FooterContainer>
      {isAuthenticated() ? (
        <NavContainer>
          <Link to="/">
            <img src={homeIcon} alt="Home" title="Home" />
          </Link>
          <a href="/perfil">
            <img src={settingsIcon} alt="Configurações" title="Configurações" />
          </a>
          <a href="/premios">
            <img src={giftIcon2} alt="Prêmios" title="Prêmios" />
          </a>
          <Link to="/perfil/editar/1">
            <img src={profileIcon} title="Perfil" alt="Perfil" />
          </Link>

          <a onClick={logout} title="Sair">
            <img src={logouticon} alt="Sair" />
          </a>
        </NavContainer>
      ) : null}
    </FooterContainer>
  );
}

export default Footer;

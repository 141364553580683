import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import GlobalStyle, { TextColorLight } from "../styles/global";
import Header from "../components/header";
import { ContentSection, Container } from '../styles/styles';
import { Link, useNavigate } from 'react-router-dom';
import { BackBar, BackButton, Pagination } from '../styles/global';
import { PrimaryColor } from '../styles/global';
import { formatDate, formatTime } from '../utils'; // Funções utilitárias de formatação
import logo from '../assets/icons/logo-banco-da-felicidade.svg';
import giftIcon from '../assets/icons/icon-gift.png';
import { getTotalUsuarios, getTotalPremios, getTotalPremiosSolicitados, getResgates, getMoedasAtribuidas } from '../controllers/dashboardController';
import { FaArrowRight } from "react-icons/fa";
import { SvgSearch } from '../assets/icons';
import { IconInput } from '../styles/global';
import breakpoints from '../styles/breakpoints';

// Função para calcular o saldo de moedas
const calcularSaldo = (moedaValor) => {
    const valores = JSON.parse(moedaValor); // Converte o JSON para um objeto
    const saldo = (valores['1'] || 0) * 1 + (valores['5'] || 0) * 5 + (valores['10'] || 0) * 10 +
        (valores['50'] || 0) * 50 + (valores['100'] || 0) * 100;
    return saldo;
};

// Estilos para o layout
const DashboardContainer = styled(Container)`
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const StatsGrid = styled(Container)`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  h2, p {
      color: ${TextColorLight};
    }
    @media screen and (max-width: ${breakpoints.sm}) {
        gap: 5px;
    }
`;

const StatCard = styled.div`
  background-color: ${(props) => props.bgColor || '#f0f0f0'};
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  flex: 1;
  &:hover {
    background-color: #FFEACC;
    h2, p {
      color: ${PrimaryColor};
    }
  }
`;

const HistoriesContainer = styled(Container)`
  display: flex;
  gap: 20px;

  @media screen and (max-width: ${breakpoints.lg}) {
    flex-direction: column;
  }
`;

const HistoriesItem = styled(Container)`
  flex-direction: column;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border: 2px solid #F59D14;
  flex: 1;
`;

const HistoryTable = styled.table`
  thead, th{
    background: none;
  }
`;

const TableData = styled.td`
  padding: 5px 3px;
  border-bottom: 1px solid #e1e1e1;
  font-size: 13px;
  width: fit-content;
  color: ${PrimaryColor};
  img {
    width: 30px;
  }

  .item-detalhes {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0px;
    span{font-size: 10px;}
  }
  .item-detalhes-data {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0px;
    small{font-size: 10px;font-weight:400}
  }
`;

// Dashboard Component
function Dashboard() {
    const [usuariosCadastrados, setUsuariosCadastrados] = useState(0);
    const [numPremios, setNumPremios] = useState(0);
    const [premiosSolicitados, setPremiosSolicitados] = useState(0);
    const [resgates, setResgates] = useState([]);
    const [filteredResgates, setFilteredResgates] = useState([]);
    const [moedasAtribuidas, setMoedasAtribuidas] = useState([]);
    const [filteredMoedas, setFilteredMoedas] = useState([]);
    const [error, setError] = useState('');
    const [currentPageResgates, setCurrentPageResgates] = useState(1);
    const [currentPageMoedas, setCurrentPageMoedas] = useState(1);
    const [searchTermMoedas, setSearchTermMoedas] = useState('');
    const [searchTermResgates, setSearchTermResgates] = useState('');
    const itemsPerPage = 10;

    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const usuariosResponse = await getTotalUsuarios();
                const premiosResponse = await getTotalPremios();
                const resgatesResponse = await getTotalPremiosSolicitados();
                const resgatesHistorico = await getResgates();
                const moedasHistorico = await getMoedasAtribuidas();

                setUsuariosCadastrados(usuariosResponse.totalUsuarios);
                setNumPremios(premiosResponse.totalPremios);
                setPremiosSolicitados(resgatesResponse.totalPremiosSolicitados);
                setResgates(resgatesHistorico);
                setFilteredResgates(resgatesHistorico);
                setMoedasAtribuidas(moedasHistorico);
                setFilteredMoedas(moedasHistorico);

            } catch (error) {
                setError('Erro ao carregar dados.');
            }
        };
        fetchData();
    }, []);

    const handleSearchMoedas = (e) => {
        const term = e.target.value.toLowerCase();
        setSearchTermMoedas(term);

        const filtered = moedasAtribuidas.filter(moeda =>
            (moeda.criacao && moeda.criacao.toLowerCase().includes(term)) ||
            (moeda.emocionador && moeda.emocionador.toLowerCase().includes(term)) ||
            (moeda.distribuidor && moeda.distribuidor.toLowerCase().includes(term)) ||
            (moeda.emocionadorUnidade && moeda.emocionadorUnidade.toLowerCase().includes(term)) ||
            (moeda.motivo && moeda.motivo.toLowerCase().includes(term))
        );

        setFilteredMoedas(filtered); // Atualiza os usuários filtrados
    };

    const handleSearchResgates = (e) => {
        const term = e.target.value.toLowerCase();
        setSearchTermResgates(term);

        const filtered = resgates.filter(resgate =>
            (resgate.nome && resgate.nome.toLowerCase().includes(term)) ||
            (resgate.cargo && resgate.cargo.toLowerCase().includes(term)) ||
            (resgate.unidade && resgate.unidade.toLowerCase().includes(term)) ||
            (resgate.email && resgate.email.toLowerCase().includes(term))
        );

        setFilteredResgates(filtered); // Atualiza os resgates filtrados
    };


    const resgatesIndexLast = currentPageResgates * itemsPerPage;
    const resgatesIndexFirst = resgatesIndexLast - itemsPerPage;
    const currentResgates = filteredResgates.slice(resgatesIndexFirst, resgatesIndexLast);

    const moedasIndexLast = currentPageMoedas * itemsPerPage;
    const moedasIndexFirst = moedasIndexLast - itemsPerPage;
    const currentMoedas = filteredMoedas.slice(moedasIndexFirst, moedasIndexLast);

    const paginateResgates = (pageNumber) => setCurrentPageResgates(pageNumber);
    const paginateMoedas = (pageNumber) => setCurrentPageMoedas(pageNumber);

    return (
        <>
            <Header />
            <GlobalStyle />
            <Container fullWidth align="center" justify="center">
                <ContentSection fullWidth direction="column">

                    <BackBar>
                        <BackButton onClick={() => navigate(-1)}>← Voltar</BackButton>
                    </BackBar>

                    <DashboardContainer fullWidth>

                        <StatsGrid light fullWidth>
                            <Link to="/usuarios">
                                <StatCard bgColor="#FF9500">
                                    <h2>{usuariosCadastrados}</h2>
                                    <p>Usuários Cadastrados</p>
                                </StatCard>
                            </Link>
                            <Link to="/premios">
                                <StatCard bgColor="#F59D14">
                                    <h2>{numPremios}</h2>
                                    <p>Número de Prêmios</p>
                                </StatCard>
                            </Link>
                            <Link to="/itens-solicitados">
                                <StatCard bgColor="#EC6726">
                                    <h2>{premiosSolicitados}</h2>
                                    <p>Prêmios Solicitados</p>
                                </StatCard>
                            </Link>
                        </StatsGrid>

                        <HistoriesContainer fullWidth direction="column">

                            {/* Histórico de Resgates */}
                            <HistoriesItem>
                                <h3>Histórico de Resgates de Prêmios</h3>

                                <HistoryTable>
                                    <thead>
                                        <tr>
                                            <th colSpan="5">
                                                <IconInput>
                                                    <SvgSearch />
                                                    <input
                                                        type="text"
                                                        placeholder="Pesquisar resgates..."
                                                        value={searchTermResgates}
                                                        onChange={handleSearchResgates}
                                                    />
                                                </IconInput>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentResgates.length > 0 ? (
                                            currentResgates.map((resgate, index) => (
                                                <tr key={index}>
                                                    <TableData><div className="item-type">
                                                        <img src={giftIcon} />
                                                        <h5>{resgate.nome} <span> x{resgate.qtd}</span></h5>
                                                    </div></TableData>
                                                    <TableData><div className="item-detalhes">
                                                        <small>{resgate.usuario}</small>
                                                        <span>{resgate.unidade}</span>
                                                    </div></TableData>
                                                    <TableData><div className="item-detalhes-data">
                                                        <small>{formatDate(resgate.criacao)}</small>
                                                        <small>{formatTime(resgate.criacao)}</small>
                                                    </div></TableData>
                                                    <TableData><small>{resgate.status}</small></TableData>
                                                    <TableData>{resgate.valor_moeda_resgatado}</TableData>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <TableData colSpan="5">Nenhum resgate encontrado.</TableData>
                                            </tr>
                                        )}
                                    </tbody>
                                </HistoryTable>

                                {/* Paginação Resgates */}
                                <Pagination>
                                    {[...Array(Math.ceil(filteredResgates.length / itemsPerPage)).keys()].map(page => (
                                        <button key={page} onClick={() => paginateResgates(page + 1)}>
                                            {page + 1}
                                        </button>
                                    ))}
                                </Pagination>
                            </HistoriesItem>

                            {/* Histórico de Moedas Atribuídas */}
                            <HistoriesItem>
                                <h3>Histórico de Moedas Atribuídas</h3>

                                <HistoryTable>
                                    <thead>
                                        <tr>
                                            <th colSpan="7">
                                                <IconInput>
                                                    <SvgSearch />
                                                    <input
                                                        type="text"
                                                        placeholder="Pesquisar atribuições..."
                                                        value={searchTermMoedas}
                                                        onChange={handleSearchMoedas}
                                                    />
                                                </IconInput>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentMoedas.length > 0 ? (
                                            currentMoedas.map((moeda, index) => (
                                                <tr key={index}>
                                                    <TableData><img src={logo} /></TableData>
                                                    <TableData>{moeda.motivo}</TableData>
                                                    <TableData><div className="item-detalhes">
                                                        <small><b>{moeda.distribuidor}</b></small>
                                                        <span>{moeda.distribuidorUnidade}</span>
                                                    </div></TableData>
                                                    <TableData>
                                                        <FaArrowRight />
                                                    </TableData>
                                                    <TableData><div className="item-detalhes">
                                                        <small>{moeda.emocionador}</small>
                                                        <span>{moeda.emocionadorUnidade}</span>
                                                    </div></TableData>
                                                    <TableData><div className="item-detalhes-data">
                                                        <small>{formatDate(moeda.criacao)}</small>
                                                        <small>{formatTime(moeda.criacao)}</small>
                                                    </div></TableData>
                                                    <TableData>{calcularSaldo(moeda.valor_moeda)}</TableData>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <TableData colSpan="7">Nenhuma moeda atribuída encontrada.</TableData>
                                            </tr>
                                        )}
                                    </tbody>
                                </HistoryTable>

                                {/* Paginação Moedas */}
                                <Pagination>
                                    {[...Array(Math.ceil(filteredMoedas.length / itemsPerPage)).keys()].map(page => (
                                        <button key={page} onClick={() => paginateMoedas(page + 1)}>
                                            {page + 1}
                                        </button>
                                    ))}
                                </Pagination>
                            </HistoriesItem>

                        </HistoriesContainer>

                    </DashboardContainer>
                </ContentSection >
            </Container>
        </>
    );
}

export default Dashboard;

import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  InfoContainer,
  InfoContainer100,
  InfoCoins,
  CloseButton,
} from "../../components/success";
import placeholderImage from "../../assets/images/placeholder.png";
import { Header } from "./styles";
import GlobalStyle, { Border } from "../../styles/global";
import { Container, ContentSection } from "../../styles/styles";
import { API_URL } from "../../controllers/api";
import { getUserData } from "../../controllers/userController";
import logo from "../../assets/icons/logo-banco-da-felicidade.svg";
import Footer from "../../components/footer";

const PremioResgatadoSuccess = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { premio, quantity } = location.state || {};
  const user = JSON.parse(localStorage.getItem("user"));
  const [userData, setUserData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      if (user?.idusuario) {
        const userInfo = await getUserData(user.idusuario);
        setUserData(userInfo);
      }
    };

    fetchData();
  }, [user?.idusuario]);


  return (
    <>
      <GlobalStyle />
      <Header style={{ paddingTop: "28px", textAlign: "center" }}>
        <img
          src={logo}
          alt="Banco da Felicidade"
          className="logo"
        />
        <div style={{padding: '12px'}}>
          <h3>Solicitação enviada!</h3>
          <p>
            A partir de 7 (sete) dias úteis, seu prêmio estará disponível para
            retirada no TCF, mediante a entrega das moedas físicas.
          </p>
        </div>
      </Header>
      <Container bgColor="#fff" direction="column" align="center" justify="center" fullWidth>
        <ContentSection maxWidth="400px" direction="column" gap="18px" padding="0px" style={{paddingTop: "92px"}} position="relative">
          <InfoContainer
            style={{
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
            position: "absolute",
            top: "-60px"
          }}
          >
          <div>
            <p  style={{ color: "red"}}>Emocionador</p>
            <p className="simpleText">
              {user.nome || "Usuário"}
            </p>
          </div>
          <div style={{ display: "flex", gap: "20px" }}>
            <div>
              <p style={{ color: "red"}}>Unidade</p>
              <p className="simpleText">
                {user.unidade || "N/A"}
              </p>
            </div>

            <div>
              <p style={{ color: "red"}}>Seção</p>
              <p className="simpleText">
                {user.setor || "N/A"}
              </p>
            </div>
          </div>
        </InfoContainer>
        <InfoCoins>
          Saldo de Moedas: {userData.saldo_moedas || "0"}
        </InfoCoins>

         <InfoContainer
           style={{
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
            marginBottom: "18px"
          }}>
                <Container gap="40px" align="center">
                  <img
                    src={
                      premio?.url_imagem
                      ? `${API_URL}${premio.url_imagem}`
                      : placeholderImage
                    }
                    alt={premio?.nome || "Imagem do Prêmio"}
                    style={{ borderRadius: "8px", width: "100px", height: "auto" }}
                    />
                  <Container
                    padding="0"
                    direction="column"
                    style={{ textAlign: "left" }}
                    >
                    <p style={{ color: "#000"}}>
                      {premio?.nome || "Prêmio"}
                    </p>

                    <p style={{ color: "#000"}}>
                      <strong>Valor:</strong> {premio?.valor || "N/A"}
                    </p>
                    <p style={{ color: "#000" }}>
                      <strong>Quantidade:</strong> {quantity || "N/A"}
                    </p>
                  </Container>
                </Container>
              </InfoContainer>
              <CloseButton onClick={() => navigate("/")}>Finalizar</CloseButton>
       </ContentSection>
       <Footer />
      </Container>
    </>
  );
};

export default PremioResgatadoSuccess;

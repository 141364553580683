import '../fonts.css';
import { createGlobalStyle } from 'styled-components';
import styled from 'styled-components';
import breakpoints from './breakpoints';
import React, { useState, useEffect } from 'react';

// Theme Colors
const PrimaryColor = '#FF2D55';
const SecondaryColor = '#FF9500';
const TextColor = '#FFFFFF';
const TextColorLight = '#ffffff';
const TextColorDark = '#585858';
const BackgroundColor = '#F3F3F3';
const BackgroundColorLight = '#EC6726';
const Border = '#e1e1e1';
const Accent = '#FFFFFF';
const Yellow = '#FFCC00'
const Blue = '#30B0C7';
const Orange = '#EC6726'
const Green = '#34C759'

// Layout Constants
const ContentWidth = '1140px';

export const Spinner = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #FF9500;
  animation: spin 1s linear infinite;

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;

const GlobalStyle = createGlobalStyle`
    
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Co Headline', sans-serif;
        font-weight: normal;
        width: 100%;
        //color: ${TextColor};
    }

    h1, h2, h3, h4, h5, h6 {
        font-family: 'Co Headline', sans-serif;
        font-weight: bold;
        color: ${TextColor};
    }


    p, span{
        color: ${TextColor};
        font-weight: lighter;
        width: inherit;
    }


    a {
        text-decoration: none;
        color: unset;
        width: inherit;
    }

    @media screen and (max-width: ${breakpoints.sm}) {
      h1{font-size: 26px;}
      h2{font-size: 22px;}
      h3{font-size: 20px;}
      h4{font-size: 18px;}
      h5{font-size: 16px;}
      h6{font-size: 14px;}
      p{font-size: 14px;}
    }

    body {
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        background-color: ${BackgroundColor};
    }

    button {
        display: flex;
        align-items: center;
        gap: 10px;
        background-color: ${PrimaryColor};
        color: ${TextColorLight};
        border: none;
        padding: 2px 8px;
        border-radius: 5px;
        cursor: pointer;
        width: fit-content;
        transition: 0.5s;

        svg {width:auto;}
    }

    input, textarea, select {
    font-size: 15px;
    font-weight: 300;
    }

    table{
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  margin-top: 20px;
  border-radius: 10px;
  overflow: hidden;
  background: #fff9f2;
  border: solid 1px #fff;

  thead {
    background-color: ${SecondaryColor};
    text-align: left;
    border: none;
    td {color: ${TextColorLight};padding: 10px;}
    }
    th{
      background-color: ${PrimaryColor};
      padding: 7px 10px;
      border: none;
      color: #fff;
      font-size: 14px;
    }
    td{
      padding: 8px 10px;
      border-bottom: 1px solid #e1e1e1;
      font-size: 13px;
      width: fit-content;
      color: ${PrimaryColor};
      img {
        width: 30px;
      }
      .item-detalhes {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0px;
        span{font-size: 10px;}
      }
      .item-detalhes-data {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0px;
        small{font-size: 10px;font-weight:400}
      }
      .item-type {
        display: flex;
        align-items: center;
        gap: 10px;
        flex: 2;
      }

      .item-time-date {
        display: flex;
        justify-content: start;
        gap: 10px;
        color: ${TextColor};
        font-size: 12px;
      }

      .item-time-date small {
        width: fit-content;
      }

      .item-amount {
        font-weight: bold;
        color: ${props => props.amountColor || TextColor};
        flex: 0.5;
        text-align: right;
      }

      .item-coins {
        display: flex;
        align-items: center;
        gap: 2px;
        img{width: 20px;}
      }
    }
    }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: ${props => props.direction || 'row'};
  justify-content: ${props => props.justify || 'flex-start'};
  align-items: ${props => props.align || 'stretch'};
  flex-wrap: ${props => props.wrap || 'nowrap'};
  background-color: ${props => props.bgColor || 'transparent'};
  padding: ${props => props.padding || '10px'};
  max-width: ${props => props.fullWidth ? '100%' : ContentWidth};
  width: ${props => props.fullWidth ? '100%' : (props.width || 'auto')};
  height: ${props => props.height || 'auto'};
  margin: ${props => props.margin || '0'};
  gap: ${props => props.gap || '2px'};
  justify-self: center;
  align-self: ${props => props.alignSelf || 'auto'};
  box-shadow: ${props => props.shadow ? '0 0 10px rgba(0,0,0,0.1)' : 'none'};
  z-index: ${props => props.zIndex || 'auto'};

  /*    
  @media (breakpoints.md) {
    max-width: 100%;
  }
    */

h1, h2, h3, h4, h5, h6, p{
    color: ${props => props.light ? TextColorLight : TextColor};
    width: auto;
}

p{
    color: ${props => props.light ? TextColorLight : TextColor};
    width: auto;
}

`;

const BackBar = styled.div`
    display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px 20px;
  max-width: 100%;
  
`;

const BackButton = styled.a`
  display: flex;
  align-items: center;
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  margin-bottom: 20px;
  width: fit-content;
  cursor: pointer;
  

  :hover {
    color: ${SecondaryColor};
  }
`;

const IconInput = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  border: none;                         
  padding: 10px;
  border-radius: 20px;
  margin-bottom: 10px;
  background: #F2F1F1;
  align-items: center;
  position: ${(props)=> props.position };
  top: ${(props)=> props.top };

    input {
        border: none!important;;
        background: none;
        color: ${TextColorDark};
        font-family: "Roboto", sans-serif;
        font-size: 14px;
        margin-left: 12px;

        &::placeholder {
            color: ${TextColorDark};
        }
        &:focus-visible {
            outline: none;
        }
    }

    svg {
      color: #000;
      width: 18px;
    }
`;

export const ViewContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border: 2px solid #F59D14;
  width: 85%;
  background-color: #FFFDFB;

  @media screen and (max-width: ${breakpoints.lg}) {
    width: 90%;
  }

`;

export const Pagination = styled.div`
display: flex;
justify-content: center;
  gap: 4px;
padding: 10px 0;
margin-top: 10px;
`;

// Estilização da mensagem de erro
export const ErrorMessage = styled.p`
    background: #EC6726;
    padding: 15px;
    border-radius: 10px;
    color: ${TextColorLight}!important;
    position: absolute;
    right: 0;
    margin-right: 10px;
    width: 300px;
    top: 10%;
  font-size: 14px;
  opacity: ${({ show }) => (show ? 1 : 0)};
  transition: opacity 0.5s ease-in-out;
`;

export const ErrorComponent = ({ message, duration = 3000 }) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (message) {
      setVisible(true);
      const timer = setTimeout(() => {
        setVisible(false);
      }, duration);

      return () => clearTimeout(timer);
    }
  }, [message, duration]);

  return (
    <ErrorMessage show={visible}>
      {message}
    </ErrorMessage>
  );
};


export default GlobalStyle;
export { PrimaryColor, SecondaryColor, TextColor, TextColorLight, TextColorDark, BackgroundColor, BackgroundColorLight, Accent, Border, Blue, Orange, Yellow, Green };
export { ContentWidth };
export { BackBar, BackButton, IconInput };